import { Customer } from '~common/app.types';
import { getLangValue } from '~common/app.utils';
import { FileAttachment } from '~common/content.types';

export function getAttachmentName(
  att: FileAttachment,
  customerAttachmentTypes: Customer['fileAttachmentTypesById']
) {
  const attachmentTypeId =
    att.propertiesById['nibo:custom-material-type'] &&
    Number.parseInt(att.propertiesById['nibo:custom-material-type']);

  return (
    getLangValue(customerAttachmentTypes?.[attachmentTypeId]) ??
    getLangValue(att.namesByLang) ??
    att.name
  );
}

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { t } from '@lingui/macro';

import { useSelector } from 'react-redux';
import { Tab } from '@mui/material';
import FolderTree from './FolderTree';
import Search from './search';
import { useSearchTarget } from './common/utils';

import { FoldersIcon, SearchIcon } from '~misc/icons';

import Tabs from '~navigation/Tabs';
import TabContent from '~sections/TabContent';
import TabPanel from '~common/navigation/TabPanel';
import { useActions, usePrevious } from '~common/utils/hooks.utils';
import { commonContent } from '~common/content.model';

interface Props {
  activeTab?: string;
}

const DockTabs: React.FC<Props> = ({ activeTab }) => {
  const config = useSelector(state => state.app.customer?.configById ?? {});
  // doesn't make sense to work any other way
  // though neither does this but oh
  const defaultTabId =
    config['content.browse.settings.mode.default'] === 'search' ? 1 : 0;

  const searchTarget = useSearchTarget();

  const [selectedTabId, setSelectedTabId] = useState(0);
  // Change tab when location changes
  const { pathname } = useLocation();
  const previousPath = usePrevious(pathname);

  const [initialTabId, setInitialTabId] = useState<number | undefined>(
    undefined
  );

  const expanded = useSelector(state => state.commonContent.expanded);
  const { setExpanded } = useActions(commonContent.actions);

  useEffect(() => {
    const selectedId =
      activeTab === 'search' || pathname.startsWith('/search')
        ? 1
        : activeTab === 'folder' || pathname.startsWith('/folders')
        ? 0
        : undefined;

    if (selectedId) setSelectedTabId(selectedId);

    if (!previousPath) {
      setInitialTabId(selectedId ?? defaultTabId);
    }
  }, [pathname, activeTab]);

  useEffect(() => {
    if (initialTabId === undefined) {
      return;
    }
    setSelectedTabId(initialTabId);
  }, [initialTabId]);

  const tabs = {
    0: {
      content: (
        <FolderTree
          type={searchTarget ?? 'folder'}
          foldersExpanded={expanded}
          setFoldersExpanded={setExpanded}
        />
      ),
      key: searchTarget,
      label: t`Folders`,
      icon: <FoldersIcon />,
    },
    1: {
      content: <Search />,
      label: t`Search`,
      icon: <SearchIcon />,
    },
  };

  return (
    <>
      <Tabs
        indicatorColor="primary"
        withBorder
        variant="fullWidth"
        value={selectedTabId}
        onChange={(event: any, value: any) => setSelectedTabId(value)}
      >
        {Object.keys(tabs).map(index => (
          <Tab
            key={Number(index)}
            label={tabs[index].label}
            icon={tabs[index].icon}
            style={{ minWidth: '107px' }}
            id={`left-dock-tab-${index}`}
            aria-controls={`left-dock-tabpanel-${index}`}
            value={Number(index)}
          />
        ))}
      </Tabs>
      <TabContent top="104px">
        {Object.keys(tabs).map(index => (
          <TabPanel
            key={`panel-${index}-${tabs[index].key ?? ''}`}
            open={Number.parseInt(index) === selectedTabId}
            id={`left-dock-tabpanel-${index}`}
            tabId={`left-dock-tab-${index}`}
          >
            {Number.parseInt(index) === selectedTabId && tabs[index].content}
          </TabPanel>
        ))}
      </TabContent>
    </>
  );
};

export default DockTabs;

import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Trans, t } from '@lingui/macro';

import Typography from '~common/misc/Typography';
import { AddMaterialIcon } from '~common/misc/icons';
import Column from '~common/layout/Column';
import { UploadFile } from '~common/content.types';
import { isIOS } from '~common/utils/browser.utils';
import { blobToUploadFile } from '~common/misc/drag/utils';
import { useActions } from '~common/utils/hooks.utils';
import { app } from '~common/app.model';

interface Props {
  fullWidth?: boolean;
  noMargin?: boolean;
  compact?: boolean;
  error?: boolean;
  versionMode?: boolean;
  multiple?: boolean;
  renameIOSImages?: boolean;
  onInsert: (files: UploadFile[]) => void;
  label?: string;
}

const DropTarget = ({
  fullWidth,
  onInsert,
  noMargin,
  compact,
  error,
  renameIOSImages,
  versionMode,
  multiple = true,
  label,
  ...rest
}: Props) => {
  const showErrorMessage = useActions(app.actions.showErrorMessage);
  const onDrop = (files: File[]) => {
    const iOS = isIOS();
    const newFiles = files
      .filter(file => file.size > 0)
      .map(file => blobToUploadFile(file, { iOS, renameIOSImages }));
    if (newFiles.length > 0) onInsert(newFiles);
    if (files.some(file => file.size === 0))
      showErrorMessage(t`Empty files can't be added`);
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple });

  return (
    <DropTargetWrapper
      {...getRootProps()}
      fullWidth={fullWidth}
      noMargin={noMargin}
      compact={compact}
      tabIndex={null}
      error={error}
      {...rest}
    >
      <DropTargetLabel variant="h2" compact={compact}>
        {label ??
          (versionMode ? (
            <Trans>Add a new version</Trans>
          ) : (
            <Trans>Add material</Trans>
          ))}
      </DropTargetLabel>
      <DropTargetAddIcon compact={compact} />
      <HiddenInput
        {...getInputProps()}
        tabIndex={0}
        aria-label={t`Add material`}
        style={{ display: 'block' }}
      />
    </DropTargetWrapper>
  );
};

// TODO: extract width and height (used on ItemThumbnail as well)
export const DropTargetWrapper = styled(Column)<any>`
  width: ${props => (props.fullWidth ? '100%' : '176px')};
  height: ${props => (props.compact ? '90px' : '155px')};
  justify-content: center;
  align-items: center;
  border: dashed 2px ${props => props.theme.palette.grey[400]};
  border-radius: 4px;
  cursor: pointer;
  margin: 0 0 12px 0;

  &:focus-within {
    border-style: solid;
    border-width: 3px;
  }

  ${({ error, theme }) =>
    error && `border: 3px solid ${theme.palette.error.main};`}
`;

// We need to destructure the 'compact' prop as MUI otherwise passes it down
// to html causing errors
export const DropTargetLabel = styled(({ compact, ...rest }) => (
  <Typography {...rest} />
))`
  && {
    margin-bottom: ${props => (props.compact ? '2px' : props.theme.spacing(1))};
  }
`;

export const DropTargetAddIcon = styled(({ compact, ...rest }) => (
  <AddMaterialIcon {...rest} />
))`
  && {
    font-size: ${props => (props.compact ? 3 : 5)}em;
    color: ${props => props.theme.palette.grey[400]};
  }
`;

const HiddenInput = styled.input`
  width: 1px;
  height: 1px;
  opacity: 0 !important;
  overflow: hidden;
`;

export default DropTarget;

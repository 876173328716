import React, { createContext } from 'react';
import { MetaField } from './content.types';
import { useCustomerMetaFields } from './content.utils';

export const CustomerMetaFieldsContext = createContext<MetaField[]>([]);

export default function GlobalContextProviders({
  children,
}: React.PropsWithChildren<{}>) {
  const metaFields = useCustomerMetaFields();

  return (
    <CustomerMetaFieldsContext.Provider value={metaFields}>
      {children}
    </CustomerMetaFieldsContext.Provider>
  );
}

import { DefaultRootState } from 'react-redux';
import { t } from '@lingui/macro';
import qs from 'qs';
import { CancelToken } from 'axios';
import {
  QueryDefinition,
  QueryLifecycleApi,
} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

import { encodeTags } from 'src/content/common/utils';
import { getApiDateTimeString, getDate, getTime } from './utils/date.utils';
import config from './config';
import { isIOS } from './utils/browser.utils';
import api, { afterQueryMessages } from './utils/api.utils';
import {
  convertFilesIn,
  convertFileIn,
  convertContentToFiles,
  convertSearchParameters,
  convertTreeElementIn,
  convertTextsOut,
  convertMetaFieldIn,
  TreeNode,
} from './content.utils';
import { content } from '../content/model';

import { Lang } from './utils/i18n';
import { TranslatedData, Criteria } from '~common/common.types';
import { apiBase, BaseQueryFn, TagTypes } from '~common/api.base';
import { useQueries } from '~common/utils/ducks.utils';
import {
  Content,
  File,
  Fileish,
  SynkkaFile,
  SynkkaCriteria,
  FolderTreeEntry,
  RemovedFile,
  fileExists,
  MetaField,
  TreeMetaField,
  TaskStatus,
} from '~common/content.types';
import { app, ShareKeyType } from '~common/app.model';
import { commonContent } from '~common/content.model';
import { filterUndefined, mapTree, objectMap } from '~common/utils/fn.utils';
import { conversionKeys, fileIncludeAllList } from '~common/content.constants';

export const getShareKeyValue = (types?: ShareKeyType[]) => {
  const k = config.shareKey;
  // Only return the correct kind of shareKey
  if (types && k && !types.includes(k[0].substring(0, 1) as ShareKeyType))
    return undefined;
  return k ? `${k[0]}/${k[1]}/${k[2]}` : undefined;
};

export const getShareKeyParameter = (options?: {
  separator?: string;
  types?: ShareKeyType[];
}) => {
  const shareKey = getShareKeyValue(options?.types);
  return shareKey ? `${options?.separator ?? '?'}shareKey=${shareKey}` : '';
};

export const withShareKey = (
  params: any,
  options?: { types?: ShareKeyType[] }
) => {
  return {
    shareKey: getShareKeyValue(options?.types) || undefined,
    ...params,
  };
};

// Files

// TODO: copy-pasted from old implementation (duplicate with new upload ui?)
export const createFiles = async (
  folderId,
  file,
  filename,
  mimetype,
  metadata = {},
  propertydata = {},
  progressCallback,
  updateOnConflict = false,
  cancelToken?: CancelToken
) => {
  const payload = {
    propertiesById: { ...propertydata },
    metaById: { ...metadata },
    name: filename,
    fileType: 'nt:file',
  };

  let id;
  try {
    const newFile = await api.http.post(`/folders/${folderId}/files/`, payload);
    id = newFile.data.id;
  } catch (e) {
    if (updateOnConflict && e.response && e.response.status === 409) {
      const parent = await readFolder({
        id: folderId,
        params: {},
      });
      if (parent.removed) throw e;
      id = await queryExistingFileId(parent.node.path, filename);
    } else throw e;
  }

  let filetype = file.type;
  // fallback to mimetype determined when pushing new files
  // at Dropzone onDrop
  if (!filetype) filetype = mimetype;

  return api.http.put(
    `/files/${id}/contents/original?filename=${filename}&onlyFile=true`,
    file,
    {
      headers: {
        'Content-Type': `${filetype}`,
      },
      onUploadProgress: event => {
        progressCallback({ uploaded: event.loaded, size: event.total });
      },
      cancelToken,
    }
  );
};

export const readFile = async ({ id, params }) => {
  const res = await api.http.get(
    `/files/${id}?${qs.stringify(withShareKey(params))}`
  );
  return convertFileIn(res.data);
};

export const readFiles = async ({ ids, params }) => {
  const res = await api.http.post(
    `/files/multiple?${qs.stringify(withShareKey(params))}`,
    {
      ids,
    }
  );
  const data = {} as Record<string, File | RemovedFile>;
  Object.keys(res.data).forEach(id => {
    if (res.data[id]) {
      data[id] = convertFileIn(res.data[id]);
    }
  });
  return data;
};

// TODO: deprecate in favor of useUpdateFile-hook below
export const updateFile = async ({ file }) => {
  const { id, name, propertiesById, metaById, infoByLang, versioning } = file;
  await api.http.put(`files/${id}`, {
    name,
    propertiesById,
    metaById,
    infoByLang,
    versioning,
  });
};

export const sendToSynkka = async ({ ids }) => {
  if (!ids || ids.length === 0) return;
  const id = ids[0];
  const res = await api.http.post<{ skipped: string[] }>(
    `files/${id}/actions/synkka?ids=${ids.toString()}`
  );
  return res;
};

export const removeFromSynkka = async ({ ids }) => {
  if (!ids || ids.length === 0) return;
  const id = ids[0];
  const res = await api.http.delete<{ skipped: string[] }>(
    `files/${id}/actions/synkka?ids=${ids.toString()}`
  );
  return res;
};

export const archiveInSynkka = async ({ ids }) => {
  if (!ids || ids.length === 0) return;
  const id = ids[0];
  const res = await api.http.put<{ skipped: string[] }>(
    `files/${id}/actions/synkka?ids=${ids.toString()}`
  );
  return res;
};

/** Asks Api to construct an order link for the selected items */
export async function getMagentoLink(
  customerId: string,
  ids: string[],
  productTypes?: string[]
) {
  try {
    const res = await api.http.post<{ status: string; returnUrl: string }>(
      `customers/${customerId}/magento`,
      { ids: ids, productTypes: productTypes }
    );
    return res.data.status.toLowerCase() === 'ok'
      ? res.data.returnUrl.replaceAll('\\', '')
      : undefined;
  } catch (e) {
    return undefined;
  }
}

// Folders
// TODO: Add locale to description or remove description
export const createFolder = async ({ folder }) => {
  const {
    folderId,
    names,
    descriptions,
    instructions,
    workflowsIds,
    conversions,
    notificationGroupIds,
    notificationEmail,
    notificationUserIds,
    notificationUserEmail,
    basketShowOnFrontpage,
    basketOnFrontpageStickyBit,
    basketIsCampaign,
    denyCrawlerBot,
    notificationTypeWeb,
    fileType,
  } = folder;
  const namesByLang = convertTextsOut(names, 'nibo:name_');
  const descriptionsByLang = convertTextsOut(descriptions, 'nibo:description_');
  const res = await api.http.post<{ id: string }>(
    `/folders/${folderId}/files/`,
    {
      name: Object.values(names)[0],
      fileType:
        fileType === 'nt:cart'
          ? 'nt:cart'
          : fileType === 'nt:archiveFolder'
          ? 'nt:archiveFolder'
          : 'nt:folder',
      propertiesById: {
        ...namesByLang,
        ...descriptionsByLang,
        'nibo:workflow-ids': workflowsIds?.join(' '),
        ...getConversionProps(conversions),
        'nibo:folder-notification-groups': notificationGroupIds?.join(', '),
        'nibo:folder-notification-type-email': notificationEmail,
        'nibo:folder-notification-users': notificationUserIds?.join(', '),
        'nibo:folder-user-notification-type-email': notificationUserEmail,
        'nibo:basket-show-on-frontpage': basketShowOnFrontpage,
        'nibo:basket-on-frontpage-sticky-bit': basketOnFrontpageStickyBit,
        'nibo:basket-is-campaign': basketIsCampaign,
        'nibo:basket-deny-crawler-bot': denyCrawlerBot,
        'nibo:folder-notification-type-web': notificationTypeWeb,
      },
      infoByLang: instructions,
    }
  );
  return res;
};

export const readFolder = async ({ id, params }) => {
  return readFile({ id, params });
};

export const downloadAsZip = ({
  selectedNodeIds,
  versions,
  workspaceId,
  nameConfigId,
  lang,
}) => {
  let query = `nodeIds=${selectedNodeIds}`;
  query += `&versions=${versions}${getShareKeyParameter({ separator: '&' })}`;
  query += workspaceId ? `&workspaceId=${workspaceId}` : '';
  query += nameConfigId ? `&nameConfigId=${nameConfigId}` : '';
  query += lang ? `&lang=${lang}` : '';
  openLink(`${config.BASE_URL}/api/v1/files/actions/download?${query}`);
};

// File and Folder

export const moveContent = async ({ move, conflict }) => {
  const { itemIds, destFolderId } = move;
  const { conflictStrategy = 'default' } = conflict || {};
  await api.http.put(`/files/move`, {
    destinationFolderUuid: destFolderId,
    selectedNodes: itemIds,
    renameIfExists: conflictStrategy === 'rename',
    override: conflictStrategy === 'override',
  });
};

export const copyContent = async ({ copy, conflict }) => {
  const { itemIds, destFolderId } = copy;
  const { conflictStrategy = 'default' } = conflict || {};
  await api.http.put(`/files/copy`, {
    destinationFolderUuid: destFolderId,
    selectedNodes: itemIds,
    renameIfExists: conflictStrategy === 'rename',
    override: conflictStrategy === 'override',
  });
};

export const linkContent = async ({ link, conflict }) => {
  const { itemIds, destFolderId, isFolder } = link;
  const { conflictStrategy = 'default' } = conflict || {};
  const res = await api.http.post<{ ids: 'MULTIPLE' }>(
    `folders/${destFolderId}/files`,
    {
      fileType: isFolder ? 'nt:linkedFolder' : 'nt:linkedFile',
      sourceFileIds: itemIds,
      override: conflictStrategy === 'override',
    }
  );
  return res;
};

// Folder content

// REFACTOR: do this in server
// -> otherwise might cause some problems with paging
const fileTypeFilterHack = (items: Array<File | RemovedFile>, params) => {
  let filtered = items;
  if (params['filter-fileType']) {
    filtered = filtered.filter(
      item =>
        params['filter-fileType'].indexOf(
          'node' in item && item.node.fileType
        ) !== -1
    );
  }
  if (params['omit-fileType']) {
    filtered = filtered.filter(
      item =>
        params['omit-fileType'].indexOf(
          'node' in item && item.node.fileType
        ) === -1
    );
  }
  return filtered;
};

export const readFolderContent = async ({ id, params }) => {
  const res = await api.http.get<any[]>(
    `/folders/${id}/files?${qs.stringify(withShareKey(params))}`
  );

  return {
    items: fileTypeFilterHack(convertFilesIn(res.data), params),
    totalCount: (res.headers['x-totalcount'] as string) || res.data?.length,
  };
};

export const deepDownloadAsZip = ({
  folderId,
  isFolderAction,
  selectedNodeIds,
  version,
  nameConfigId,
  lang,
}) => {
  let query = `nodeIds=${selectedNodeIds}`;
  query += isFolderAction ? `&isFolderAction=true` : '';
  query += `&version=${version}`;
  query += nameConfigId ? `&nameConfigId=${nameConfigId}` : '';
  query += lang ? `&lang=${lang}` : '';
  openLink(
    `${config.BASE_URL}/api/v1/folder/${folderId}/download/zip?${query}`
  );
};

// Search content

export const readContent = async ({ customerId, params, returnObjects }) => {
  const res = await api.http.get<Content[]>(
    `/customers/${customerId}/contents?${qs.stringify(
      convertSearchParameters(params),
      { arrayFormat: 'repeat' }
    )}`
  );

  return {
    items: returnObjects ? convertContentToFiles(res.data) : res.data,
    totalCount: (res.headers['x-totalcount'] as string) || res.data?.length,
  };
};

export const queryExistingFileId = async (
  parentPath: string,
  fileName: string
) => {
  const parent =
    parentPath.indexOf('/_customers/') === 0
      ? parentPath.substring(12)
      : parentPath.indexOf('/') === 0
      ? parentPath.substring(1)
      : parentPath;
  const params = {
    path: `${parent}/${fileName}`,
  };
  try {
    const response = await api.http.get(
      `${config.BASE_URL}/api/v2/fileIds?${qs.stringify(withShareKey(params))}`
    );
    return response.data.id as string;
  } catch (e) {
    return null;
  }
};

// Upload / download

interface DownloadProps {
  id: string;
  type: string;
  size?: any;
  crop?: any;
  maskid?: any;
  nameConfigId?: number;
  lang?: string;
}

export const getAudioLink = ({ id, type, lang }: DownloadProps) => {
  return getLink({
    id,
    type,
    isDownload: true,
    lang,
  });
};

// NOTE: copied from old implementation
export const download = ({
  id,
  type,
  size,
  crop,
  maskid,
  nameConfigId,
  lang,
}: DownloadProps) => {
  const link = getLink({
    id,
    type,
    isDownload: true,
    size,
    crop,
    maskid,
    nameConfigId,
    lang,
  });
  openLink(link);
};

export const getLink = ({
  id,
  type,
  isDownload,
  size,
  crop,
  maskid,
  nameConfigId,
  lang,
}: DownloadProps & { isDownload: boolean }) => {
  let url = `${config.apiUrl}/files/${id}/contents/${type}?download=${isDownload}`;
  url += nameConfigId ? `&nameConfigId=${nameConfigId}` : '';
  url += lang ? `&lang=${lang}` : '';
  if (size && size.x && size.y) {
    url = url.concat(
      `&sizex=${Math.round(size.x)}&sizey=${Math.round(size.y)}`
    );
  }
  if (crop && (crop.x1 || crop.x2 || crop.y1 || crop.y2)) {
    url = url.concat(`&x1=${Math.round(crop.x1)}&y1=${Math.round(crop.y1)}`);
    url = url.concat(`&x2=${Math.round(crop.x2)}&y2=${Math.round(crop.y2)}`);
  }
  if (maskid) {
    url = url.concat(`&maskid=${maskid}`);
  }
  url = url.concat(getShareKeyParameter({ separator: '&' }));
  return url;
};

export const getDownloadPdfLink = (
  userProductId: string,
  pdfType: 1 | 2,
  download: boolean,
  isMassdata?: boolean
) =>
  `${config.url}/NiboWEB/${config.customer}/getPDF.do?${
    download ? 'devevent=false&' : ''
  }&type=userPDF&userId=${userProductId}&PDFType=${pdfType}${
    isMassdata ? '&isMassdata=true' : ''
  }`;

// NOTE: copied from old implementation
export const downloadPdf = (userProductId: string, pdfType: 1 | 2) => {
  const link = getDownloadPdfLink(userProductId, pdfType, true, false);
  openLink(link);
};

export const openLink = link => {
  if (isIOS()) {
    // iOS blocks window.open calls on async functions
    setTimeout(() => {
      const opened = window.open(link, '_top');
      if (
        !opened ||
        opened === null ||
        opened.closed ||
        typeof opened.closed === 'undefined'
      ) {
        // window.open failed, try to open in new tab
        window.open(link, '_blank');
      }
    });
  } else {
    window.location.href = `${link}`;
  }
};

export const readOriginalContent = async ({ id }) => {
  const res = await api.http.get(`files/${id}/contents/original`);
  return res.data;
};

export const getVideoUrl = async ({ id, isMobile, location }) => {
  const params = {
    command: 'getVideoPreviewInfo',
    uuid: id,
    isMobile,
    location,
  };
  const res = await api.http.get(
    `${config.BASE_URL}/NiboWEB/${
      config.customer
    }/taskManagement.do?${qs.stringify(params)}`
  );
  return res.data;
};

// Workflows

// TODO remove this
export const fetchWorkflows = async ({ id, params }) => {
  const res = await api.http.get(
    `workflows/${id}/settings?${qs.stringify(
      params || { include: 'info,translatedDefaults' }
    )}`
  );
  return res.data;
};

export const fetchMetaFieldSuggestions = async ({
  customerId,
  search,
  metaFieldId,
  lang,
  defLang,
}: {
  customerId: string;
  metaFieldId: string;
  search: string;
  lang: string;
  defLang: string;
}) => {
  const params = {
    search,
    // fields: ['_meta_...'] doesn't work
    'fields[]': `_meta_${metaFieldId}_txt`,
    limit: 10,
    lang,
    defaultLang: defLang,
  };

  const res = await api.http.get(
    `customers/${customerId}/suggestions?${qs.stringify(params)}`
  );
  return res.data;
};

type ReadFolderParams = {
  /** include inheritedMetaById */
  inheritmeta?: boolean;
  /** include inheritCustomUploadLayout */
  inheritlayout?: boolean;
};

type GetFolderParams = {
  id: string;
  include?: string;
  params?: ReadFolderParams;
  password?: string;
};

type UpdateFolderParams = {
  folderId: string;
  name?: string;
  names: TranslatedData;
  descriptions: TranslatedData;
  instructions: TranslatedData;
  workflowsIds?: string[] | number[];
  conversions?: string[];
  notificationGroupIds?: string[] | number[];
  notificationEmail?: boolean;
  notificationUserIds?: string[];
  notificationUserEmail?: boolean;
  basketShowOnFrontpage?: boolean;
  basketOnFrontpageStickyBit?: boolean;
  basketIsCampaign?: boolean;
  denyCrawlerBot?: boolean;
  notificationTypeWeb?: boolean;
  sharePeriod?: boolean;
  expirationDate?: string | null;
  expirationTime?: string | null;
  isPublic?: boolean;
  workspacePassword?: string;
};

type GetFileParams = {
  id: string;
  include?: string;
  password?: string;
  parentId?: string;
  isCropView?: 'true';
  lang?: Lang;
};

type GetExistingFileParams = {
  parentPath: string;
  filename: string;
};

type CreateFileParams = {
  folderId: string;
  filename: string;
  metaById?: Record<string, unknown>;
  propertiesById?: Record<string, unknown>;
  shareKey?: string;
};

type CreateFileMultipartParams = {
  folderId: string;
  filename: string;
  blob: Blob;
  metaById?: Record<string, unknown>;
  propertiesById?: Record<string, unknown>;
  infoByLang?: Record<string, unknown>;
  mimetype: string;
  chunkFileName?: string;
  params?: {
    shareKey?: string;
    rename?: boolean;
  };
  progressCallback?: (currentProgress: number) => void;
};

type PutFileChunkParams = {
  folderId: string;
  chunkFileName: string;
  chunkNumber: number;
  totalChunks: number;
  blob: Blob;
  mimetype: string;
  progressCallback?: (currentProgress: number) => void;
};

type PutFileParams = {
  fileId: string;
  filename: string;
  blob: Blob;
  mimetype: string;
  versioning?: boolean;
  /** Called with a percentage of progress for the current file being uploaded */
  progressCallback?: (currentProgress: number) => void;
  shareKey?: string;
};

type UpdateFileParams = {
  id: string;
  name: string;
  propertiesById: Record<string, unknown>;
  metaById: Record<string, unknown>;
  infoByLang?: Record<string, unknown>;
  versioning: boolean;
};

type RemoveFileVersionParams = {
  id: string;
  versionId: string;
};

type UpdateFileMultipartParams = {
  id: string;
  filename: string;
  versioning: boolean | undefined;
  onlyFile: boolean;
  blob: Blob;
  mimetype: string;
  propertiesById: Record<string, unknown>;
  metaById: Record<string, unknown>;
  infoByLang?: Record<string, unknown>;
  chunkFileName?: string;
  /** Called with a percentage of progress for the current file being uploaded */
  progressCallback?: (currentProgress: number) => void;
};

type GetContentsParams = {
  id?: string;
  criteria?: Omit<Criteria, 'selectedId' | 'selectedIndex'>;
  include?: string;
  password?: string;
  language?: Lang;
  shareKey?: string;
  useFastEndpoint?: boolean;
  report?: boolean;
};

type GetContentsData = {
  items: File[];
  totalCount: number;
  concreteId?: string;
};

type GetCustomerMetaFieldsParams = {
  customerId: string;
  withShareKey?: boolean;
};

export type UpdatedCategoryTree = Pick<
  TreeNode,
  'parentId' | 'id' | 'namesByLang'
> & { removed?: boolean; placement?: number; children: UpdatedCategoryTree[] };
type UpdateCategoryTreeParams = {
  customerId: string;
  metaField: TreeMetaField;
  tree: UpdatedCategoryTree;
};

export type FolderDownloadConfig = {
  enabledDeepDownload: boolean;
  folderDownloadLevel: number;
};

export type FolderDownloadContent = {
  id: string;
  items: (File | RemovedFile)[];
  downloadVersions?: Record<string, Record<string, string>>;
  folderDownloadLevelLimit?: number;
  filesSize?: number;
  foldersCount?: number;
  filesCount?: number;
  totalCount?: number;
  originalFilesSize?: number;
};

export type GetFolderDownloadContentParams = {
  id: string;
  deep: boolean;
  params: {
    isFolderAction?: boolean;
    lang: string;
    isCropView?: string;
    include?: string;
  };
};

/** Generates the tag with which queries of a file can be invalidated. */
export const getFileTag = (fileId: string) => ({
  type: 'File' as const,
  id: fileId,
});

export const getFileVersionTag = (fileId: string) => ({
  type: 'Version' as const,
  id: fileId,
});

const getConversionIds = (
  conversions: string[] | undefined,
  profile: string
) => {
  return conversions
    ?.filter(field => field.startsWith(`${profile}.`))
    .reduce((acc, field) => [...acc, field.split('.')[1]], []);
};

const getConversionProps = (conversions: string[] | undefined) => {
  // basic conversions false or empty by default
  const defaultProps = {
    [conversionKeys.highRes]: false,
    [conversionKeys.webImg]: false,
    [conversionKeys.pdfLow]: false,
    [conversionKeys.imageProfiles]: '',
    [conversionKeys.videoProfiles]: '',
    [conversionKeys.audioProfiles]: '',
  };

  // basic conversions
  const basicProps = conversions
    ?.filter(field => field.startsWith('nibo:'))
    .reduce((acc, field) => ({ ...acc, [field]: true }), {});

  // imageConversions
  const imageConversionIds = getConversionIds(
    conversions,
    'imageConvertProfiles'
  );

  // videoConversions
  const videoConversionIds = getConversionIds(
    conversions,
    'videoConvertProfiles'
  );

  // audioConversions
  const audioConversionIds = getConversionIds(
    conversions,
    'audioConvertProfiles'
  );

  return {
    ...defaultProps,
    ...basicProps,
    [conversionKeys.imageProfiles]: imageConversionIds?.join(','),
    [conversionKeys.videoProfiles]: videoConversionIds?.join(','),
    [conversionKeys.audioProfiles]: audioConversionIds?.join(','),
  };
};

async function fetchBasicContents(
  { id, criteria, password, language }: GetContentsParams,
  getState: () => DefaultRootState,
  fetch: (params: {
    baseURL?: string;
    url: string;
    params?: object;
    method: 'get';
  }) => ReturnType<BaseQueryFn>
) {
  const state = getState();
  const lang = language ?? state.app.settings?.language;

  if (!id) throw Error('id is required');

  const params = { lang, ...getSearchParams(criteria, password) };

  const response = await fetch({
    baseURL: `${config.BASE_URL}/api/v2`,
    url: `/folders/${id}/files`,
    params: withShareKey(params),
    method: 'get',
  });

  if (response.error) return { error: response.error };

  const items = response.data as File[];
  const totalCount =
    Number(response.meta?.headers['x-totalcount']) ||
    (response.data as unknown[])?.length;
  const concreteId = response.meta?.headers['x-concreteid'];

  return {
    data: { items, totalCount, concreteId },
  };
}

async function fetchContents(
  { id, criteria, include, password, language, report }: GetContentsParams,
  getState: () => DefaultRootState,
  fetch: (params: { url: string; method: 'get' }) => ReturnType<BaseQueryFn>
) {
  const state = getState();
  const lang = language ?? state.app.settings?.language;
  const customerId = state.app.customer?.id;
  const userContentFolderId = state.app.settings?.userContentFolderId;

  let params = getSearchParams(criteria, password);

  let result: Awaited<ReturnType<typeof fetch>>;
  if (id) {
    // Read folder content
    params = {
      include:
        include ??
        'basic,rights,synkka,publicity,users,emails,path,allowedShare',
      lang,
      omit: id === userContentFolderId ? 'cart' : undefined,
      ...params,
    };
    result = await fetch({
      url: `/folders/${id}/files?${qs.stringify(withShareKey(params))}`,
      method: 'get',
    });
  } else {
    // Read search content
    params = {
      type: 'material', // Search only material by default
      include: 'synkka,emails,emailCount,thumbnails',
      lang,
      report,
      ...params,
    };
    result = await fetch({
      url: `/customers/${customerId}/contents?${qs.stringify(
        convertSearchParameters(params),
        { arrayFormat: 'repeat' }
      )}`,
      method: 'get',
    });
  }
  if (result.error) {
    return { error: result.error };
  } else {
    const items = id
      ? (fileTypeFilterHack(
          convertFilesIn(result.data as unknown[]),
          params
        ) as File[])
      : convertContentToFiles(result.data as Content[]);
    const totalCount =
      Number(result.meta?.headers['x-totalcount']) ||
      (result.data as unknown[])?.length;
    const concreteId = result.meta?.headers['x-concreteid'];
    return {
      data: { items, totalCount, concreteId },
    };
  }
}

interface SearchParams {
  offset?: number;
  limit?: number;
  sort?: string;
  tags?: string;
  password?: string;
  fileSizeMin?: number;
  fileSizeMax?: number;
  [key: string]: any;
}

const getSearchParams = (
  criteria: Omit<Criteria, 'selectedId' | 'selectedIndex'> | undefined,
  password?: string
): SearchParams => {
  const {
    page,
    pageSize,
    sortBy,
    tags,
    fileSizeMin,
    fileSizeMax,
    expandId,
    ...rest
  } = criteria ?? {};

  const params = {
    offset: page && pageSize ? page * pageSize : 0,
    limit: pageSize,
    sort: sortBy,
    tags: tags && encodeTags(tags),
    password,
    ...rest,
    fileSizeMin: fileSizeMin && Math.round(fileSizeMin * 1048576),
    fileSizeMax: fileSizeMax && Math.round(fileSizeMax * 1048576),
    collapse: !expandId,
    originalNodeId: expandId,
  };

  Object.keys(params).forEach(
    key => params[key] === undefined && delete params[key]
  );

  return params;
};

const syncItemsToFilesById = (
  items: File[],
  dispatch: ThunkDispatch<DefaultRootState, any, AnyAction>
) => {
  const filesById = items.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.node.id]: curr,
    }),
    {} as Record<string, File>
  );
  dispatch(commonContent.actions.afterReadFiles(filesById));
};

const getFileFetch = ({ id, ...params }: GetFileParams) => {
  const urlParams = {
    include: fileIncludeAllList, // REFACTOR: perhaps publicity should be retrieved only when required,
    ...params,
  };

  return {
    url: `/files/${id}?${qs.stringify(withShareKey(urlParams))}`,
    method: 'get' as const,
  };
};

const getFilesFetch = ({
  ids,
  include,
}: {
  ids: string[];
  include?: string;
}) => ({
  url: `/files/multiple?${qs.stringify(
    withShareKey({
      include: `basic,image,${include || ''}`,
    })
  )}`,
  data: { ids },
  method: 'post' as const,
});

type QueryApi<QueryArg, ResultType> = QueryLifecycleApi<
  QueryArg,
  BaseQueryFn,
  ResultType,
  'api'
>;

/** Callback function for conditional polling in query endpoints.
 * Should be used as the onQueryStarted callback.
 * Will poll and update the cache entry without activeting the build-in
 * isFetching status flags.
 */
const poll =
  <QueryArg, ResultType>(
    shouldPoll: (data: ResultType) => boolean,
    fetch: (
      queryArg: QueryArg,
      api: QueryApi<QueryArg, ResultType>
    ) => Promise<{ data?: ResultType }>,
    onFreshDataReceived?: (
      data: ResultType,
      api: QueryApi<QueryArg, ResultType>
    ) => void,
    ms = 30000
  ) =>
  async (queryArg: QueryArg, api: QueryApi<QueryArg, ResultType>) => {
    let data: ResultType | undefined;
    try {
      const { data: initialData } = await api.queryFulfilled;
      data = initialData;
    } catch (_e) {}

    // The initial fetch failed. Can't do anything.
    if (!data) return;

    // We good. Don't need to do anything
    if (!shouldPoll(data)) return;

    // Setup an interval to poll fresh data from server
    const interval = setInterval(async () => {
      try {
        const { data: freshData } = await fetch(queryArg, api);

        if (!freshData) return; // Error. Will try again in the next iteration

        api.updateCachedData(() => freshData);
        onFreshDataReceived?.(freshData, api);

        const entry = api.getCacheEntry();
        if (!shouldPoll(freshData) || entry.isUninitialized) {
          // All things clear (or the cache entry is removed). Will stop now.
          clearInterval(interval);
        }
      } catch (_e) {}
    }, ms);
  };

const getFileResponseHandlers = <
  GetArgs extends GetFolderParams | GetFileParams
>(
  overwriteStateMeta?: boolean
): Pick<
  QueryDefinition<GetArgs, BaseQueryFn, TagTypes, Fileish>,
  'transformResponse' | 'onQueryStarted' | 'providesTags'
> => ({
  transformResponse: data => convertFileIn(data),
  onQueryStarted: async (params, { dispatch, queryFulfilled }) => {
    try {
      const { data: file } = await queryFulfilled;
      // Manually add all read folders into filesById
      dispatch(
        commonContent.actions.afterReadFile(
          params.id,
          file,
          overwriteStateMeta ||
            ('include' in params && params.include?.includes('meta'))
        )
      );
    } catch (e) {
      /* We need to catch this to avoid error splat screens */
    }
  },
  providesTags: (result, __, { id }) => [
    getFileTag(id),
    ...(result && !result.removed
      ? [
          getFileTag(result.node.id),
          result?.concrete && getFileTag(result.concrete.id),
        ].filter(filterUndefined)
      : []),
  ],
});

interface FetchResult {
  data: any;
  error: any;
}

const extendedApi = apiBase.injectEndpoints({
  endpoints: builder => ({
    getFolder: builder.query<Fileish, GetFolderParams>({
      query: ({ id, include, params, password }) => {
        const urlParams = {
          include: `basic,rights,path,info,publicity,settings,${include || ''}`,
          password,
          ...params,
        };

        return {
          url: `/files/${id}?${qs.stringify(withShareKey(urlParams))}`,
          method: 'get',
        };
      },
      ...getFileResponseHandlers<GetFolderParams>(),
    }),

    updateFolder: builder.mutation<string[], UpdateFolderParams>({
      query: ({
        folderId,
        name,
        names,
        descriptions,
        instructions,
        workflowsIds,
        conversions,
        notificationGroupIds,
        notificationUserIds,
        notificationEmail,
        notificationUserEmail,
        basketShowOnFrontpage,
        basketOnFrontpageStickyBit,
        basketIsCampaign,
        denyCrawlerBot,
        notificationTypeWeb,
        expirationDate,
        expirationTime,
        isPublic,
        workspacePassword,
        sharePeriod,
      }) => {
        const namesByLang = convertTextsOut(names, 'nibo:name_');
        const descriptionsByLang = convertTextsOut(
          descriptions,
          'nibo:description_'
        );

        return {
          url: `/files/${folderId}`,
          method: 'put',
          data: {
            name,
            propertiesById: {
              ...namesByLang,
              ...descriptionsByLang,
              'nibo:workflow-ids': workflowsIds?.join(' '),
              ...getConversionProps(conversions),
              'nibo:folder-notification-groups':
                notificationGroupIds?.join(', '),
              'nibo:folder-notification-users': notificationUserIds?.join(', '),
              'nibo:folder-notification-type-email': notificationEmail,
              'nibo:folder-user-notification-type-email': notificationUserEmail,
              'nibo:basket-show-on-frontpage': basketShowOnFrontpage,
              'nibo:basket-on-frontpage-sticky-bit': basketOnFrontpageStickyBit,
              'nibo:basket-is-campaign': basketIsCampaign,
              'nibo:basket-deny-crawler-bot': denyCrawlerBot,
              'nibo:folder-notification-type-web': notificationTypeWeb,
              'nibo:has-public-sharing-validity-period': sharePeriod,
              'nibo:publicly-shared-endTime':
                expirationDate && expirationTime
                  ? getApiDateTimeString(
                      getDate(expirationDate),
                      getTime(expirationTime)
                    )
                  : undefined,
              'nibo:sharing': isPublic ? '3' : '2',
              'nibo:public-password': !!workspacePassword,
              'nibo:public-password-value': workspacePassword,
            },
            infoByLang: instructions,
          },
        };
      },
      onQueryStarted: (_, args) =>
        afterQueryMessages(t`Folder updated`, t`Edit failed`, args),
      invalidatesTags: (result, __, { folderId }) => [
        'FolderTree',
        getFileTag(folderId),
        ...(result?.map(id => getFileTag(id)) || []),
      ],
    }),

    getFile: builder.query<Fileish, GetFileParams>({
      query: getFileFetch,
      ...getFileResponseHandlers<GetFileParams>(true),
      onQueryStarted: poll<GetFileParams, Fileish>(
        item =>
          Boolean(
            !item.removed &&
              (item.previewInProcessing ||
                item.synkkaInProcessing ||
                (item.inProcessing && !item.longProcessing))
          ),
        queryArg =>
          api.http
            .get<Fileish>(getFileFetch(queryArg).url)
            .then(({ data }) => ({ data: convertFileIn(data) })),
        () => {},
        5000
      ),
    }),

    /** Fetches multiple files from the single file endpoint.
     *
     * NOTE: the same effect could be achieved with `useGetFileQueries`,
     * but those requests are handled in sequence which results in unnecessary waiting.
     * So, in order to be more performant,
     * this query loses the more robust caching of `useGetFileQueries` */
    getMultipleFiles: builder.query<
      Fileish[],
      { ids: string[]; params: Omit<GetFileParams, 'id'> }
    >({
      queryFn: async ({ ids, params }, _, __, fetch) => {
        const promises = ids.map(async id => {
          const result = await fetch({
            url: `/files/${id}?${qs.stringify(withShareKey(params))}`,
            method: 'get',
          });
          return result;
        });
        const results = await Promise.all(promises);

        const error = results.find(item => item.error);
        if (error) {
          return { error };
        }

        return {
          data: results
            .map(result =>
              result.data ? convertFileIn(result.data) : undefined
            )
            .filter(filterUndefined),
        };
      },
      providesTags: (data, _error, { ids }) =>
        data
          ? [
              ...ids.map(getFileTag),
              ...Object.values(data)
                .filter((file): file is File => !file.removed)
                .flatMap(file => [
                  getFileTag(file.node.id),
                  file.concrete && getFileTag(file.concrete.id),
                ])
                .filter(filterUndefined),
            ]
          : [],
    }),

    /** Gets files with a more performant single query,
     * but has only a limited set of supported include parameters  */
    getFiles: builder.query<
      Record<string, Fileish>,
      { ids: string[]; include?: string }
    >({
      query: getFilesFetch,
      onQueryStarted: poll<
        { ids: string[]; include?: string },
        Record<string, Fileish>
      >(
        data =>
          Object.values(data).some(item =>
            Boolean(
              !item.removed &&
                (item.previewInProcessing ||
                  item.synkkaInProcessing ||
                  (item.inProcessing && !item.longProcessing))
            )
          ),
        queryArg =>
          api.http
            .post<Record<string, unknown>>(
              getFilesFetch(queryArg).url,
              getFilesFetch(queryArg).data
            )
            .then(({ data }) => ({
              data: objectMap(data, row => convertFileIn(row)),
            })),
        () => {},
        5000
      ),
      transformResponse: (data: Record<string, unknown>) => {
        return objectMap(data, row => convertFileIn(row));
      },
      providesTags: results =>
        results
          ? [
              ...Object.keys(results).map(getFileTag),
              ...Object.values(results)
                .filter((file): file is File => !file.removed)
                .flatMap(file => [
                  getFileTag(file.node.id),
                  file.concrete && getFileTag(file.concrete.id),
                ])
                .filter(filterUndefined),
            ]
          : [],
    }),

    getExistingFile: builder.query<{ id: string }, GetExistingFileParams>({
      query: ({ parentPath, filename }) => {
        const parent =
          parentPath.indexOf('/_customers/') === 0
            ? parentPath.substring(12)
            : parentPath.indexOf('/') === 0
            ? parentPath.substring(1)
            : parentPath;

        return {
          baseURL: `${config.BASE_URL}/api/v2`,
          url: `/fileIds?path=${encodeURIComponent(`${parent}/${filename}`)}`,
          method: 'get',
        };
      },
    }),

    createFile: builder.mutation<{ id: string }, CreateFileParams>({
      query: ({
        folderId,
        filename,
        metaById = {},
        propertiesById = {},
        shareKey,
      }) => ({
        url: `/folders/${folderId}/files${
          shareKey ? `?shareKey=${shareKey}` : ''
        }`,
        method: 'post',
        data: { name: filename, fileType: 'nt:file', metaById, propertiesById },
      }),
      // We don't invalidate the folder of the new file here, as this
      // endpoint is always used with at least `putFile`, sometimes
      // `updateFile` too. If more hooks were to use this, be sure to
      // manually `invalidateFile(folderId)` after the mutation to avoid
      // stale cache!
    }),

    createFileMultipart: builder.mutation<
      { id: string },
      CreateFileMultipartParams
    >({
      query: ({
        folderId,
        filename,
        blob,
        metaById,
        propertiesById,
        infoByLang,
        mimetype,
        chunkFileName,
        params,
        progressCallback,
      }) => {
        const formData = new FormData();
        formData.append(
          'json',
          new Blob(
            [
              JSON.stringify({
                name: filename,
                fileType: 'nt:file',
                propertiesById,
                infoByLang,
                metaById,
                chunkFileName,
              }),
            ],
            { type: 'application/json;charset=utf-8' }
          )
        );
        formData.append(
          'file',
          blob.type ? blob : new Blob([blob], { type: mimetype })
        );

        return {
          url: `/folders/${folderId}/files?${qs.stringify(params ?? {})}`,
          method: 'post',
          data: formData,
          headers: {
            'Content-Type': `multipart/form-data; boundary=${
              (formData as any)._boundary
            }`,
          },
          onUploadProgress: event =>
            progressCallback?.((event.loaded / event.total) * 100),
        };
      },
      invalidatesTags: (result, __, { folderId }) => [
        getFileTag(result?.id ?? folderId),
      ],
    }),

    putFile: builder.mutation<void, PutFileParams>({
      query: ({
        fileId,
        filename,
        blob,
        mimetype,
        versioning,
        progressCallback,
        shareKey,
      }) => ({
        url: `/files/${fileId}/contents/original?filename=${encodeURIComponent(
          filename
        )}&versioning=${versioning || false}&onlyFile=true${
          shareKey ? `&shareKey=${shareKey}` : ''
        }`,
        method: 'put',
        data: blob,
        headers: { 'Content-Type': `${blob.type || mimetype}` },
        onUploadProgress: event =>
          progressCallback?.((event.loaded / event.total) * 100),
      }),
    }),

    uploadFileChunk: builder.mutation<void, PutFileChunkParams>({
      query: ({
        folderId,
        chunkFileName,
        chunkNumber,
        totalChunks,
        blob,
        mimetype,
        progressCallback,
      }) => {
        const params = {
          chunkFileName,
          chunkNumber,
          totalChunks,
        };
        return {
          url: `/folders/${folderId}/files/uploadChunk?${qs.stringify(
            withShareKey(params)
          )}`,
          method: 'put',
          data: blob,
          headers: { 'Content-Type': `${mimetype}` },
          onUploadProgress: event =>
            progressCallback?.(
              ((chunkNumber + event.loaded / event.total) / totalChunks) * 100
            ),
        };
      },
    }),

    updateFile: builder.mutation<void, UpdateFileParams>({
      query: ({ id, ...data }) => ({
        url: `/files/${id}`,
        method: 'put',
        data,
      }),
      invalidatesTags: (_, __, { id }) => [getFileTag(id)],
    }),

    removeFileVersion: builder.mutation<void, RemoveFileVersionParams>({
      query: ({ id, versionId }) => ({
        url: `/files/${id}/version/${versionId}`,
        method: 'delete',
      }),
      invalidatesTags: (_, __, { id, versionId }) => [
        getFileTag(id),
        getFileVersionTag(versionId ?? 'Version'),
      ],
    }),

    updateFileMultipart: builder.mutation<void, UpdateFileMultipartParams>({
      query: ({
        id,
        filename,
        blob,
        versioning,
        onlyFile,
        mimetype,
        propertiesById,
        metaById,
        infoByLang,
        chunkFileName,
        progressCallback,
      }) => {
        const formData = new FormData();
        formData.append(
          'node',
          new Blob(
            [
              JSON.stringify({
                name: filename,
                versioning,
                onlyFile,
                propertiesById,
                infoByLang,
                metaById,
                chunkFileName,
              }),
            ],
            { type: 'application/json;charset=utf-8' }
          )
        );
        formData.append(
          'content',
          blob.type ? blob : new Blob([blob], { type: mimetype })
        );

        return {
          url: `${config.API_URL}/files/${id}`,
          method: 'put',
          data: formData,
          headers: {
            'Content-Type': `multipart/form-data; boundary=${
              (formData as any)._boundary
            }`,
          },
          onUploadProgress: event =>
            progressCallback?.((event.loaded / event.total) * 100),
        };
      },
      invalidatesTags: (_, __, { id }) => [getFileTag(id)],
    }),

    getContents: builder.query<GetContentsData, GetContentsParams>({
      queryFn: async (queryArg, { getState, dispatch }, _, fetch) => {
        if (
          queryArg.useFastEndpoint &&
          !queryArg.password &&
          !getShareKeyValue() &&
          queryArg.id
        ) {
          const basicContentsPromise = fetchBasicContents(
            queryArg,
            getState as () => DefaultRootState,
            fetch
          );
          const detailedContentsPromise = fetchContents(
            queryArg,
            getState as () => DefaultRootState,
            fetch
          ).then(contentsResult => {
            if (contentsResult.data?.items) {
              syncItemsToFilesById(contentsResult.data.items, dispatch);
              dispatch(
                extendedApi.util.updateQueryData(
                  'getContents',
                  queryArg,
                  () => contentsResult.data
                )
              );
            }
            return contentsResult;
          });
          const result = await Promise.any([
            basicContentsPromise,
            detailedContentsPromise,
          ]);
          return result;
        } else {
          const contentsResult = await fetchContents(
            queryArg,
            getState as () => DefaultRootState,
            fetch
          );
          if (contentsResult.data?.items) {
            syncItemsToFilesById(contentsResult.data.items, dispatch);
          }
          return contentsResult;
        }
      },
      onQueryStarted: poll<GetContentsParams, GetContentsData>(
        ({ items }) => items.some(item => item.previewInProcessing),
        (queryArg, cacheApi) =>
          fetchContents(
            queryArg,
            cacheApi.getState as unknown as () => DefaultRootState,
            ({ url }) => api.http.get(url)
          ),
        (data, api) => syncItemsToFilesById(data.items, api.dispatch)
      ),
      providesTags: (result, _, args) => [
        ...(args.id ? [getFileTag(args.id)] : []),
        ...(result?.concreteId ? [getFileTag(result.concreteId)] : []),
        ...(result?.items?.filter(fileExists).flatMap(file => {
          const nodeTag = getFileTag(file.node.id);
          // If file is a link, we want to link cache entries to both
          // the link node and the concrete node
          if (file.node.isLink && file.concrete?.id) {
            const parentTag = getFileTag(file.node.parentId);
            return [nodeTag, parentTag, getFileTag(file.concrete.id)];
          } else {
            return nodeTag;
          }
        }) ?? []),
      ],
    }),

    markMaterialAsLiked: builder.mutation<
      void,
      {
        id: string;
        dislike?: boolean;
        /** Parameters with which the contents of the view were fetched.
         * These are used to optimistically update the contents of the view without tag invalidation.
         * If no params are provided, the tags will be invalidated. */
        getContentsParams?: GetContentsParams;
      }
    >({
      query: ({ id, dislike }) => ({
        url: `/files/${id}/actions/like`,
        method: !dislike ? 'post' : 'delete',
      }),
      onQueryStarted: ({ id, dislike, getContentsParams }, args) =>
        afterQueryMessages(
          !dislike ? t`Liked` : t`Unliked`,
          t`Operation failed`,
          args,
          {
            onSuccess: () => {
              if (!getContentsParams) return;
              args.dispatch(
                extendedApi.util.updateQueryData(
                  'getContents',
                  getContentsParams,
                  draft => ({
                    ...draft,
                    items: (draft.items as File[]).map(x =>
                      x.node.id === id
                        ? {
                            ...x,
                            isLikedByUser: dislike ? undefined : true,
                          }
                        : x
                    ),
                  })
                )
              );
            },
          }
        ),
      invalidatesTags: (_, __, { id, getContentsParams }) =>
        getContentsParams ? [] : [getFileTag(id)],
    }),

    getSynkkaContent: builder.query<
      {
        items: SynkkaFile[];
        totalCount: number;
        latestFetchFromSynkka: TaskStatus;
      },
      SynkkaCriteria
    >({
      queryFn: async (arg, api, extraOptions, fetch) => {
        const params = {
          sort: arg.sortBy,
          sort_dir: arg.sortDir,
          page: arg.page,
          pageSize: arg.pageSize,
          synkkaStatus: arg.synkkaStatus,
        };

        const [result, latestFetchResponse] = await Promise.all([
          fetch({
            url: `files/synkka?${qs.stringify(params)}`,
            method: 'GET',
          }),
          fetch({
            url: `files/synkka/latestFetchFromSynkka`,
            method: 'GET',
          }),
        ]);

        const totalCount =
          Number(result.meta?.headers['x-totalcount']) ||
          (result.data as unknown[])?.length;

        const items = convertFilesIn(result?.data as any[]) as SynkkaFile[];
        const latestFetchFromSynkka = latestFetchResponse?.data as TaskStatus;
        return {
          data: { items, totalCount, latestFetchFromSynkka },
        };
      },
      providesTags: (result, _, __) =>
        result?.items?.flatMap(file => getFileTag(file.node.id)) ?? [],
    }),

    filesCustomerSend: builder.mutation<
      string[],
      { action: string; ids: string[]; fields?: Record<string, unknown> }
    >({
      query: ({ action, ids, fields }) => {
        return {
          url: `files/customer/send/${action}`,
          method: 'POST',
          data: { ids, fields },
        };
      },
      onQueryStarted: (_, args) =>
        afterQueryMessages(t`Send`, t`Send failed`, args),
      invalidatesTags: (_, __, arg) => arg.ids.map(id => getFileTag(id)),
    }),

    sendToSynkka: builder.mutation<void, { ids: string[] }>({
      query: arg => ({
        url: `files/${arg.ids[0]}/actions/synkka?ids=${arg.ids.toString()}`,
        method: 'POST',
      }),
      invalidatesTags: (_, __, arg) => arg.ids.map(id => getFileTag(id)),
    }),

    removeFromSynkka: builder.mutation<void, { ids: string[] }>({
      query: arg => ({
        url: `files/${arg.ids[0]}/actions/synkka?ids=${arg.ids.toString()}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, arg) => arg.ids.map(id => getFileTag(id)),
    }),

    archiveInSynkka: builder.mutation<void, { ids: string[] }>({
      query: arg => ({
        url: `files/${arg.ids[0]}/actions/synkka?ids=${arg.ids.toString()}`,
        method: 'PUT',
      }),
      invalidatesTags: (_, __, arg) => arg.ids.map(id => getFileTag(id)),
    }),

    markOldSynkkaItemAsNonPrimaryImage: builder.mutation<
      { success: boolean },
      { id: string }
    >({
      query: arg => ({
        url: `files/${arg.id}/actions/synkka/markOldAsNonPrimaryImage`,
        method: 'PUT',
      }),
      invalidatesTags: (_, __, arg) => [getFileTag(arg.id)],
    }),

    fetchAllFromSynkka: builder.mutation<void, { ids: string[] }>({
      query: () => ({
        url: `files/synkka/fetchFromSynkka`,
        method: 'PUT',
      }),
      invalidatesTags: (_, __, arg) => arg.ids.map(id => getFileTag(id)),
    }),

    getLatestMaterials: builder.query<
      {
        pinned: File[];
        unpinned: File[];
      },
      { customerId: string; limit?: number }
    >({
      queryFn: async ({ customerId, limit = 15 }, _, __, fetch) => {
        const results = await Promise.all(
          ['true', 'false'].map(showOnFrontpage =>
            fetch({
              url: `/customers/${customerId}/contents`,
              method: 'get',
              params: {
                type: 'material',
                mimeGroups: 'picture,video,audio,adobe,cad,text,presentation',
                limit,
                sort: 'orderByCreated',
                entryType: 'concrete',
                showOnFrontpage,
              },
            })
          )
        );
        if (results.some(item => item.error)) {
          return {
            error: results.find(item => item.error) as NonNullable<
              typeof results[number]['error']
            >,
          };
        } else {
          const [pinned, unpinned] = results.map(item =>
            convertContentToFiles((item?.data as Content[]) ?? []).filter(
              (item): item is File => !item.removed
            )
          );
          return { data: { pinned, unpinned } };
        }
      },
    }),

    getFolderTree: builder.query<
      FolderTreeEntry,
      {
        language?: string;
        type?: 'folder' | 'archive';
        depth?: number;
        rootId?: string;
      }
    >({
      queryFn: async (
        { rootId, language, type = 'folder', depth },
        { getState, dispatch },
        __,
        fetch
      ) => {
        const state = getState() as DefaultRootState;
        const rootFolderId =
          rootId !== undefined
            ? rootId
            : type === 'folder'
            ? state.app.settings?.contentFolderId
            : state.app.settings?.archiveFolderId;
        const locale =
          language ??
          state.app.settings?.language ??
          state.app.customer?.defaultLanguage;

        const showFileCount =
          state.app.customer?.configById['tree.recursive.file.count'];

        const basicTreePromise = fetch({
          url: depth
            ? `/folders/${rootFolderId}/files/tree?lang=${locale}&include=basic&depth=${depth}`
            : `/folders/${rootFolderId}/files/tree?lang=${locale}&include=basic`,
          method: 'get',
        });

        const detailedTreePromise = showFileCount
          ? Promise.any([
              fetch({
                url: depth
                  ? `/folders/${rootFolderId}/files/tree?lang=${locale}&include=basic,file_count&depth=${depth}`
                  : `/folders/${rootFolderId}/files/tree?lang=${locale}&include=basic,file_count`,
                method: 'get',
              }),
            ]).then(detailedTreeResult => {
              if (detailedTreeResult.data) {
                convertTreeElementIn(detailedTreeResult.data);
                dispatch(
                  extendedApi.util.updateQueryData(
                    'getFolderTree',
                    { rootId, language, type, depth },
                    () => detailedTreeResult.data as FolderTreeEntry
                  )
                );
              }
              return detailedTreeResult;
            })
          : undefined;

        const result = await Promise.any(
          [basicTreePromise, detailedTreePromise].filter(filterUndefined)
        );

        if (result.error) {
          return { error: result.error };
        } else {
          convertTreeElementIn(result.data);
          return { data: result.data as FolderTreeEntry };
        }
      },
      providesTags: (results, _error, { type = 'folder' }) => [
        type === 'folder' ? 'FolderTree' : 'ArchiveTree',
        ...(results ? mapTree(results, node => getFileTag(node.node.id)) : []),
      ],
    }),

    deleteContent: builder.mutation<void, File[]>({
      queryFn: async (items, { dispatch }, __, fetch) => {
        // Group each item to be deleted by their parent id so that
        // we can reduce the amount of necessary requests
        const itemsByParentId = items.reduce(
          (acc, item) => ({
            ...acc,
            [item.node.parentId]:
              item.node.parentId in acc
                ? [...acc[item.node.parentId], item.node.id]
                : [item.node.id],
          }),
          {} as Record<string, string[]>
        );

        const results = await Promise.all(
          Object.entries(itemsByParentId).map(([parentId, itemIds]) =>
            fetch({
              url: `/folders/${parentId}/files?ids=${itemIds.join(',')}`,
              method: 'delete',
            })
          )
        );

        if (results.some(result => result.error)) {
          dispatch(app.actions.showErrorMessage(t`Delete failed`));
          return { error: results.find(result => result.error) };
        } else {
          dispatch(content.actions.setItemsChecked(items, false));
          dispatch(app.actions.showInfoMessage(t`Deleted`));

          return { data: undefined };
        }
      },
      invalidatesTags: (_, __, files) => [
        'FolderTree',
        ...files.map(file => getFileTag(file.node.id)),
      ],
    }),

    getCustomerMetaFields: builder.query<
      MetaField[],
      GetCustomerMetaFieldsParams
    >({
      query: ({ customerId, withShareKey }) => ({
        url: `/customers/${customerId}/meta${
          withShareKey ? getShareKeyParameter() : ''
        }`,
        method: 'get',
      }),
      transformResponse: (data: unknown[]) => data.map(convertMetaFieldIn),
      providesTags: ['MetaFields'],
    }),

    updateCategoryTree: builder.mutation<MetaField[], UpdateCategoryTreeParams>(
      {
        query: ({ customerId, metaField, tree }) => ({
          url: `/customers/${customerId}/meta/${metaField.valueTree.id}`,
          method: 'put',
          data: tree,
        }),
        transformResponse: (data: unknown[]) => data.map(convertMetaFieldIn),
        onQueryStarted: async (
          { customerId },
          { dispatch, queryFulfilled }
        ) => {
          try {
            const { data } = await queryFulfilled;
            dispatch(
              updateQueryData(
                'getCustomerMetaFields',
                { customerId },
                () => data
              )
            );
            dispatch(app.actions.showInfoMessage(t`Category tree updated`));
          } catch {
            dispatch(invalidateTags(['MetaFields']));
            dispatch(app.actions.showErrorMessage(t`Edit failed`));
          }
        },
      }
    ),

    getFolderDownloadConfig: builder.query<
      FolderDownloadConfig,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/folder/${id}/download/config`,
        method: 'get',
      }),
    }),

    getFolderDownloadContent: builder.query<
      FolderDownloadContent,
      GetFolderDownloadContentParams
    >({
      queryFn: async ({ id, deep, params }, _, __, fetch) => {
        const result = await fetch({
          url: `${
            deep
              ? `/folder/${id}/download/deep/content?`
              : `/folders/${id}/files?`
          }${qs.stringify(withShareKey(params))}`,
          method: 'get',
        });
        if (result.error) {
          return { error: result.error };
        } else if (deep) {
          const data = result.data as FolderDownloadContent;
          return {
            data: {
              id,
              items: fileTypeFilterHack(convertFilesIn(data.items), params),
              downloadVersions: data.downloadVersions ?? {},
              folderDownloadLevelLimit: data.folderDownloadLevelLimit,
              originalFilesSize: data.filesSize,
              foldersCount: data.foldersCount,
              filesCount: data.filesCount,
              totalCount:
                Number(result.meta?.headers['x-totalcount']) ||
                data.items?.length,
            },
          };
        } else {
          const data = result.data as (File | RemovedFile)[];
          return {
            data: {
              id,
              items: fileTypeFilterHack(convertFilesIn(data), params),
              totalCount:
                Number(result.meta?.headers['x-totalcount']) || data?.length,
            },
          };
        }
      },
      providesTags: (_, __, { id }) => [getFileTag(id)],
    }),
  }),
});

const {
  util: { invalidateTags, updateQueryData },
  endpoints: { getCustomerMetaFields },
} = extendedApi;

export const invalidateFile = (id: string) => invalidateTags([getFileTag(id)]);
export const invalidateFiles = (ids: string[]) =>
  invalidateTags(ids.map(getFileTag));
export const invalidateFolderTree = () => invalidateTags(['FolderTree']);

export const customerMetaFieldsCacheSelect = getCustomerMetaFields.select;

export const { getFile, getFolder } = extendedApi.endpoints;

export const {
  useGetFolderQuery,
  useGetFolderTreeQuery,
  useGetContentsQuery,
  useUpdateFolderMutation,
  useDeleteContentMutation,
  useLazyGetExistingFileQuery,
  useMarkMaterialAsLikedMutation,
  useGetSynkkaContentQuery,
  useFilesCustomerSendMutation,
  useSendToSynkkaMutation,
  useRemoveFromSynkkaMutation,
  useArchiveInSynkkaMutation,
  useMarkOldSynkkaItemAsNonPrimaryImageMutation,
  useFetchAllFromSynkkaMutation,
  useCreateFileMutation,
  useCreateFileMultipartMutation,
  useGetFileQuery,
  useGetFilesQuery,
  useGetMultipleFilesQuery,
  useUploadFileChunkMutation,
  usePutFileMutation,
  useUpdateFileMutation,
  useRemoveFileVersionMutation,
  useUpdateFileMultipartMutation,
  useGetCustomerMetaFieldsQuery,
  useUpdateCategoryTreeMutation,
  useGetLatestMaterialsQuery,
  useGetFolderDownloadConfigQuery,
  useGetFolderDownloadContentQuery,
} = extendedApi;

export const { usePrefetch } = extendedApi;

export const useGetFolderDownloadContentQueries = useQueries(
  extendedApi.endpoints.getFolderDownloadContent
);
export const useGetFolderQueries = useQueries(extendedApi.endpoints.getFolder);
export const useGetFileQueries = useQueries(extendedApi.endpoints.getFile);

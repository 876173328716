import qs from 'qs';
import axios from 'axios';

import { getShareKeyParameter, openLink } from '~common/content.api';
import config from '~common/config';
import api from '~utils/api.utils';
import { UploadFile } from '~common/content.types';

export let uploadCancelSource = axios.CancelToken.source();
export const resetCancelSource = () =>
  (uploadCancelSource = axios.CancelToken.source());

export const fetchCommentRequests = async ({ criteria }) => {
  // TODO: use offset and limit on API
  const cr = {
    ...criteria,
    startIndex: criteria.page * criteria.pageSize,
    offset: criteria.page * criteria.pageSize,
    limit: criteria.pageSize,
    orderBy: criteria.sortBy,
  };
  const res = await api.http.get(
    `commentings?${qs.stringify(
      cr
    )}&include=progress,rights,fieldValues${getShareKeyParameter({
      separator: '&',
      types: ['o'],
    })}`
  );
  return res.data;
};

export const readCommentRequest = async ({
  commentRequestId,
}): Promise<any> => {
  const commentRequest = await api.http.get(
    `/commentings/${commentRequestId}?include=pages,fieldValues,comments,users,rights,attachments,versions,progress${getShareKeyParameter(
      {
        separator: '&',
        types: ['o'],
      }
    )}`
  );
  return commentRequest.data;
};

export const readComments = async ({ commentRequestId }): Promise<any> => {
  const comments = await readCommentsOnPage({ commentRequestId, pageId: 1 });
  return comments;
};

export const readCommentsOnPage = async ({
  commentRequestId,
  pageId,
}): Promise<any> => {
  const comments = await api.http.get(
    `commentings/${commentRequestId}/pages/${pageId}?include=comments${getShareKeyParameter(
      {
        separator: '&',
        types: ['o'],
      }
    )}`
  );
  return comments.data;
};

export const approvePage = async ({
  commentRequestId,
  selectedPageId,
  approved,
}) => {
  return api.http.post(
    `/commentings/${commentRequestId}/actions/${
      approved ? 'approve' : 'disapprove'
    }/page/${selectedPageId}${getShareKeyParameter({
      types: ['o'],
    })}`
  );
};

export const disapproveAllPages = async ({ commentRequestId }) => {
  return api.http.post(
    `/commentings/${commentRequestId}/actions/disapprove${getShareKeyParameter({
      types: ['o'],
    })}`
  );
};

export const approveAllPages = async ({ commentRequestId }) => {
  return api.http.post(
    `/commentings/${commentRequestId}/actions/approve${getShareKeyParameter({
      types: ['o'],
    })}`
  );
};

export const createComment = async ({
  type,
  position,
  commentRequestId,
  selectedPageId,
}) => {
  const { x, y, h, w } = position;
  const comment = await api.http.put(
    `/commentings/${commentRequestId}/pages/${selectedPageId}/comments${getShareKeyParameter(
      {
        types: ['o'],
      }
    )}`,
    { x, y, h, w, type, text: '' }
  );
  return comment.data;
};

export const deleteComment = async ({
  commentRequestId,
  selectedPageId,
  id,
}) => {
  return api.http.delete(
    `/commentings/${commentRequestId}/pages/${selectedPageId}/comments/${id}${getShareKeyParameter(
      {
        types: ['o'],
      }
    )}`
  );
};

export const updateComment = async ({
  commentId,
  comment,
  commentRequestId,
  selectedPageId,
}) => {
  const { x, y, w, h, type, text } = comment;
  const updated = await api.http.post(
    `commentings/${commentRequestId}/pages/${selectedPageId}/comments/${commentId}${getShareKeyParameter(
      {
        types: ['o'],
      }
    )}`,
    { x, y, h, w, type, text }
  );
  return updated.data;
};
// NOTE: copied from old implementation
export const downloadPdf = (commentRequestUuid: string, versionNr = 1) => {
  const link = `${
    config.url
  }/api/v1/files/actions/download-pdf/?commentId=${commentRequestUuid}&versionNr=${versionNr}&allPages=true${getShareKeyParameter(
    {
      separator: '&',
      types: ['o'],
    }
  )}`;
  openLink(link);
};

export const sendPdf = (commentRequestId, emailInput) => {
  return api.http.post(
    `/commentings/${commentRequestId}/actions/send${getShareKeyParameter({
      types: ['o'],
    })}`,
    {
      ...emailInput,
    }
  );
};

export const readCommenters = async () => {
  const res = await api.http.get('users/commenters');
  return res.data;
};

export const readCommenterGroups = async () => {
  const res = await api.http.get('users/commenters/groups');
  return res.data;
};

export const sendCommentRequest = async data => {
  const res = await api.http.put('commentings', data);
  return res.data;
};

export const updateCommentRequest = async ({
  commentRequestId,
  commentRequestData,
}) => {
  const res = await api.http.post(`/commentings/${commentRequestId}`, {
    ...commentRequestData,
  });
  return res.data;
};

export const closeCommenting = async ({ commentRequestId }) => {
  const res = await api.http.post(
    `/commentings/${commentRequestId}/actions/close`
  );
  return res.data;
};

export const finalizeCommenting = async ({ commentRequestId }) => {
  const res = await api.http.post(
    `/commentings/${commentRequestId}/actions/finalize`
  );
  return res.data;
};

export const updateEditableStatus = async ({
  commentRequestId,
  editableStatus,
}) => {
  const res = await api.http.put(
    `/commentings/${commentRequestId}/update_editable_status`,
    { status: editableStatus }
  );
  return res.data;
};

export const uploadNewVersion = async (
  commentRoundId: string,
  fileId: string,
  file: UploadFile,
  onProgress: (payload: { uploaded: number; size: number }) => any
) => {
  return api.http.put(
    // TODO: change endpoint
    `/commentings/${commentRoundId}/new_round/${fileId}?filename=${file.name}`,
    file.blob,
    {
      headers: {
        'Content-Type': `${file.type}/${file.subtype}`,
      },
      onUploadProgress: event => {
        onProgress({ uploaded: event.loaded, size: event.total });
      },
      cancelToken: uploadCancelSource.token,
    }
  );
};

import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components';
import {
  CircularProgress,
  IconButton,
  TablePagination,
  Tooltip,
} from '@mui/material';
import { Helmet } from 'react-helmet';
import { Container } from '@mui/system';

import { useGetConsentStatusHistoryQuery } from './api';
import { ConsentStatusHistory, GetConsentStatusHistoryParams } from './types';
import {
  getConsentStatusColor,
  getLocalizedConsentStatus,
} from '../common/utils';
import { InfoTag } from '../common/components/InfoTag';
import {
  DataTable,
  ColumnDescriptor,
  ColumnRendererProps,
} from '~common/tables/DataTable';
import { useActions } from '~common/utils/hooks.utils';

import { history } from '~common/config';
import Page from '~common/sections/Page';
import DataTableWrapper from '~common/tables/DataTableWrapper';
import { useSearchParams } from '~common/utils/browser.utils';
import { ConsentStatus } from '~common/content.constants';
import { getDateString, getTime } from '~common/utils/date.utils';
import { FileIcon, OpenIcon } from '~common/misc/icons';
import { app } from '~common/app.model';
import { Typography } from '~common/misc/Typography';
import Button from '~common/inputs/Button';
import Row from '~common/layout/Row';

type SearchQueryParams = {
  [key in keyof GetConsentStatusHistoryParams]: string | undefined;
};

function useQueryFilters() {
  const [searchParams, setSearchParams] = useSearchParams<SearchQueryParams>();

  const queryFilters = useMemo(() => {
    const { page, pageSize, fileNodeId } = searchParams;
    if (fileNodeId) {
      return {
        page: parseInt(page ?? '0'),
        pageSize: parseInt(pageSize ?? '100'),
        fileNodeId: parseInt(fileNodeId),
      };
    } else {
      return {
        page: parseInt(page ?? '0'),
        pageSize: parseInt(pageSize ?? '100'),
      };
    }
  }, [searchParams]);

  return [queryFilters, setSearchParams] as const;
}

const ConsentStatusHistoryPage = () => {
  const [queryFilters, setSearchParams] = useQueryFilters();
  const {
    data: historyData,
    isLoading,
    isFetching,
  } = useGetConsentStatusHistoryQuery(queryFilters);

  const columns: ColumnDescriptor<ConsentStatusHistory>[] = [
    {
      name: t`Created`,
      dataAccessor: item =>
        (item.created &&
          item.created.length > 0 &&
          getDateString(item.created) + ' ' + getTime(item.created)) ||
        '',
      width: '10rem',
    },
    {
      name: queryFilters.fileNodeId ? t`File name` : t`Filter by file`,
      dataAccessor: x => x.nodeName,
      renderer: FileName,
    },
    {
      name: t`Open file`,
      renderer: OpenFile,
      stopRendererPropagation: true,
    },
    {
      name: t`Previous status`,
      dataAccessor: x => x.oldStatus || '',
      renderer: function oldStatus({ item }) {
        return (
          <StatusTag status={item.oldStatus}>
            {getLocalizedConsentStatus(item.oldStatus)}
          </StatusTag>
        );
      },
    },
    {
      name: t`New status`,
      dataAccessor: x => x.newStatus || '',
      renderer: function newStatus({ item }) {
        return (
          <StatusTag status={item.newStatus}>
            {getLocalizedConsentStatus(item.newStatus)}
          </StatusTag>
        );
      },
    },
    {
      name: t`Consent terms`,
      renderer: ({ item, children }) => ConsentTerms({ item, children }),
      stopRendererPropagation: true,
    },
  ];

  const handlePageChange = (event, page: number) => {
    setSearchParams(params => ({ ...params, page: `${page}` }));
  };

  const totalCount = historyData?.totalCount;
  const title = queryFilters.fileNodeId
    ? t`Consent status history for file` + ' ' + historyData?.list[0]?.nodeName
    : t`Consent status history`;

  return (
    <PageWrapper>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Typography variant="h1" renderAs="h1">
        {title}
      </Typography>

      <ContentWrapper>
        {queryFilters.fileNodeId && (
          <Row>
            <Button
              size="medium"
              variant="contained"
              fullWidth={false}
              onClick={() =>
                setSearchParams({
                  page: '0',
                  pageSize: queryFilters.pageSize.toString(),
                })
              }
            >
              {t`Show status changes for all files`}
            </Button>
          </Row>
        )}

        {isLoading || isFetching ? (
          <LoadingSpinnerWrapper>
            <CircularProgress />
          </LoadingSpinnerWrapper>
        ) : (
          <DataTableWrapper>
            <DataTable
              label={t`Consent status history`}
              columns={columns}
              rows={historyData?.list ?? []}
              outlined={false}
            />
            {totalCount !== undefined && (
              <TablePagination
                count={totalCount}
                onPageChange={handlePageChange}
                page={queryFilters.page}
                rowsPerPage={queryFilters.pageSize}
                rowsPerPageOptions={[]}
              />
            )}
          </DataTableWrapper>
        )}
      </ContentWrapper>
    </PageWrapper>
  );
};

const FileName = ({ item }: ColumnRendererProps<ConsentStatusHistory>) => {
  const [queryFilters, setSearchParams] = useQueryFilters();

  const handleClick = () => {
    setSearchParams({
      page: '0',
      pageSize: queryFilters.pageSize.toString(),
      fileNodeId: item.fileNodeId.toString(),
    });
  };

  if (queryFilters.fileNodeId) {
    return <span>{item.nodeName}</span>;
  } else {
    return (
      <a
        onClick={handleClick}
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
          color: 'blue',
        }}
      >
        {item.nodeName}
      </a>
    );
  }
};

const OpenFile = ({ item }: ColumnRendererProps<ConsentStatusHistory>) => {
  return (
    <Tooltip title={t`Open file`}>
      <IconButton
        size="medium"
        onClick={() => history.push('/search?search=' + item.nodeName)}
      >
        <OpenIcon />
      </IconButton>
    </Tooltip>
  );
};

const ConsentTerms = ({ item }: ColumnRendererProps<ConsentStatusHistory>) => {
  const setOpenModal = useActions(app.actions.setOpenModal);

  return (
    <Tooltip title={t`Read terms`}>
      <IconButton
        size="medium"
        disabled={!item.consentTermGroupId}
        onClick={() =>
          setOpenModal('CONSENT/READ_TERMS', {
            consentTermGroupId: item.consentTermGroupId,
          })
        }
      >
        <FileIcon />
      </IconButton>
    </Tooltip>
  );
};

const StatusTag = styled(InfoTag)<{
  status: ConsentStatus;
}>`
  background-color: ${p =>
    p.status ? getConsentStatusColor(p.status, p.theme) : 'none'};
  min-width: 16px;
  text-transform: uppercase;
`;

const PageWrapper = styled(Page)`
  align-self: center;
  width: 1200px;
  gap: ${p => p.theme.spacing(2)};
  padding: ${p => p.theme.spacing(4)};
`;

const ContentWrapper = styled(Container)`
  padding: ${p => p.theme.spacing(3)};
  gap: ${p => p.theme.spacing(4)};

  @media (max-width: 640px) {
    padding-left: ${({ theme }) => theme.spacing(1)};
    padding-right: ${({ theme }) => theme.spacing(1)};
    gap: ${p => p.theme.spacing(2)};
  }
`;

const LoadingSpinnerWrapper = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  }
`;

export default ConsentStatusHistoryPage;

import api from './utils/api.utils';
import config from './config';
import { asJSON } from './user.api';
import { getShareKeyParameter } from './content.api';

export const readCustomer = async ({ customerName }): Promise<any> => {
  const response = await api.http.get(
    `${config.url}/api/v2/customers?path=${customerName}`
  );
  return {
    customer: {
      ...response.data,
      theme: asJSON(response.data.theme),
    },
    sessionLanguage: response.headers['x-sessionlanguage'] as string,
  };
};

export const readCustomerFunctions = async ({ customerId }): Promise<any> => {
  const response = await api.http.get(
    `${
      config.url
    }/api/v1/customers/${customerId}/function${getShareKeyParameter({
      types: ['f', 'w'],
    })}`
  );
  return response.data;
};

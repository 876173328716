import { t } from '@lingui/macro';
import { Criteria } from './common.types';

export const getFileTypes = () => {
  return {
    'nt:linkedFile': {
      name: t`nt:linkedFile`,
    },
    'nt:linkedFolder': {
      name: t`nt:linkedFolder`,
    },
    'nt:file': {
      name: t`nt:file`,
    },
    'nt:folder': {
      name: t`nt:folder`,
    },
    'nt:masterProduct': {
      name: t`nt:masterProduct`,
    },
    'nt:userProduct': {
      name: t`nt:userProduct`,
    },
    'nt:archiveFolder': {
      name: t`nt:archiveFolder`,
    },
    'nibo:attachment': {
      name: t`nibo:attachment`,
    },
    'nibo:attachments': {
      name: t`nibo:attachments`,
    },
    'nibo:versionHistory': {
      name: t`nibo:versionHistory`,
    },
    'nibo:version': {
      name: t`nibo:version`,
    },
    'nibo:metadata': {
      name: t`nibo:metadata`,
    },
    'nt:resource': {
      name: t`nt:resource`,
    },
  };
};

export const fileUnitTrans = () => {
  return {
    bytes: {
      name: t`bytes`,
    },
    kb: {
      name: t`kb`,
    },
    mb: {
      name: t`mb`,
    },
    gb: {
      name: t`gb`,
    },
    tb: {
      name: t`tb`,
    },
  };
};

export const typeSearch = () => {
  return [
    { name: t`any`, value: 'any' },
    { name: t`customer`, value: 'customer' },
    { name: t`user`, value: 'user' },
    { name: t`group`, value: 'group' },
    { name: t`material`, value: 'material' },
    { name: t`news`, value: 'news' },
    { name: t`order`, value: 'order' },
    { name: t`offer`, value: 'offer' },
    { name: t`comment`, value: 'comment' },
    { name: t`external`, value: 'external' },
    { name: t`externalFile`, value: 'externalFile' },
  ];
};

export const materialTypeSearch = () => {
  return [
    { name: t`any`, value: 'any' },
    { name: t`folder`, value: 'folder' },
    { name: t`file`, value: 'file' },
    { name: t`cart`, value: 'cart' },
    { name: t`campaign`, value: 'campaign' },
    { name: t`masterProduct`, value: 'masterProduct' },
    { name: t`userProduct`, value: 'userProduct' },
    { name: t`shopping`, value: 'shopping' },
    { name: t`externalFile`, value: 'externalFile' },
  ];
};

export const entryTypeSearch = () => {
  return [
    { name: t`any`, value: 'any' },
    { name: t`concrete`, value: 'concrete' },
    { name: t`link`, value: 'link' },
  ] as const;
};

export const mimeGroupsSearch = () => {
  return [
    { name: t`picture`, value: 'picture' },
    { name: t`video`, value: 'video' },
    { name: t`audio`, value: 'audio' },
    { name: t`adobe`, value: 'adobe' },
    { name: t`cad`, value: 'cad' },
    { name: t`text`, value: 'text' },
    { name: t`presentation`, value: 'presentation' },
  ] as const;
};

export const materialTypesSearch = () => {
  return [
    { name: t`Folder`, value: 'folder' },
    { name: t`Template`, value: 'masterProduct' },
    { name: t`Workspace`, value: 'cart' },
  ] as const;
};

export const fileTypeSearch = () => {
  return [
    { name: t`any`, value: 'any' },
    { name: t`text/plain`, value: 'text/plain' },
    { name: t`image/jpeg`, value: 'image/jpeg' },
    { name: t`image/gif`, value: 'image/gif' },
    { name: t`image/tiff`, value: 'image/tiff' },
    { name: t`image/png`, value: 'image/png' },
    { name: t`image/psd`, value: 'image/psd' },
    {
      name: t`application/postscript`,
      value: 'application/postscript',
    },
    { name: t`application/pdf`, value: 'application/pdf' },
    { name: t`application/dxf`, value: 'application/dxf' },
    { name: t`un-known`, value: 'un-known' },
    { name: t`image/svg+xml`, value: 'image/svg+xml' },
  ];
};

export const langSearch = () => {
  return [
    { name: t`fi`, value: 'fi' },
    { name: t`en`, value: 'en' },
    { name: t`se`, value: 'se' },
    { name: t`de`, value: 'de' },
    { name: t`fr`, value: 'fr' },
    { name: t`ru`, value: 'ru' },
    { name: t`lt`, value: 'lt' },
    { name: t`pl`, value: 'pl' },
    { name: t`lv`, value: 'lv' },
    { name: t`no`, value: 'no' },
    { name: t`dk`, value: 'dk' },
    { name: t`et`, value: 'et' },
  ];
};

export const sortSearch = () => {
  return [
    { name: t`default`, value: 'default' },
    { name: t`orderByName`, value: 'orderByName' },
    { name: t`orderByLastUsed`, value: 'orderByLastUsed' },
    { name: t`orderByCreated`, value: 'orderByCreated' },
  ];
};

export const defaultCriteria = {
  selectedIndex: -1,
  selectedId: null,
  page: 0,
  pageSize: 1000,
  sortBy: 'name',
  tags: [],
};
export const criteriaKeys: (keyof Criteria)[] = [
  'selectedId',
  'selectedIndex',
  'page',
  'pageSize',
  'sortBy',
  'search',
  'tags',
];

export const fileIncludeAllList =
  'basic,image,meta,info,validity,attachments,downloadOptions,path,users,rights,publicity,synkka,original,workflows,price,emails,versions,allowedShare,linkedFiles';

export enum UploadStatus {
  WAITING = 'WAITING',
  UPLOADING = 'UPLOADING',
  CANCELLED = 'CANCELLED',
  IGNORED = 'IGNORED',
  UPLOADED = 'UPLOADED',
  ERROR = 'ERROR',
}

export enum ConsentStatus {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  NOT_REQUESTED = 'NOT_REQUESTED',
  NOT_TAGGED = 'NOT_TAGGED',
  NOT_RESPONDED = 'NOT_RESPONDED',
  CLOSED = 'CLOSED',
  MISSING_TERMS = 'MISSING_TERMS',
}

export const conversionKeys = {
  highRes: 'nibo:folder-shared-material-high-res',
  webImg: 'nibo:folder-shared-material-web-img',
  pdfLow: 'nibo:folder-shared-material-pdf-low',
  imageProfiles: 'nibo:folder-convert-profiles',
  videoProfiles: 'nibo:folder-video-convert-profiles',
  audioProfiles: 'nibo:folder-audio-convert-profiles',
};

/**
 * Cloudflare can optimize image files if they are of certain types, here we
 * list all of those currently supported
 *
 * ref: https://developers.cloudflare.com/images/image-resizing/format-limitations/
 */
export const cdnConversionMimeTypes = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/svg+xml',
];

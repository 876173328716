import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { CircularProgress, Tooltip, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { t } from '@lingui/macro';

import { app } from '~common/app.model';
import ItemThumbnail from '~common/items/ItemThumbnail';
import { CloseIcon } from '~common/misc/icons';
import { appearForwards } from '~common/utils/animations.utils';
import { EditIcon } from '~misc/icons';
import { UploadFile } from '~common/content.types';
import { UploadStatus } from '~common/content.constants';
import { useActions } from '~common/utils/hooks.utils';
import { Chip } from '~common/items/Chips';
interface SlideProps {
  file: UploadFile;
  index: number;
  editable?: boolean;
  displayName?: string;
  disableRemoving?: boolean;
  removableOnError?: boolean;
  removableOnSuccess?: boolean;
  labelText?: string;
  variant?: ComponentProps<typeof ItemThumbnail>['variant'];
  selected?: boolean;
  onRemove: (index: number) => void;
  onNameChange?: (name: string, index: number) => void;
  onSelect?: () => void;
  warningMessage?: string;
  className?: string;
  imageWrapperProps?: React.ComponentProps<
    typeof ItemThumbnail
  >['wrapperProps'];
}

export const SuccessCheckIcon = styled(CheckIcon)`
  color: ${p => p.theme.palette.commenting.status.success};
`;

function getItemToolTipTitle(displayName, file) {
  const itemName = displayName ?? file.name;
  return file.uploadStatus === UploadStatus.IGNORED &&
    file.validateResult?.errors
    ? itemName
        .concat(' ')
        .concat(file.uploadStatus)
        .concat(': ')
        .concat(file.validateResult?.errors.join(','))
    : itemName;
}

const FileSlide = ({
  file,
  index,
  onRemove,
  onNameChange,
  onSelect,
  selected,
  editable = true,
  variant = 'elevated',
  displayName,
  labelText,
  removableOnError = false,
  removableOnSuccess = false,
  disableRemoving = false,
  warningMessage,
  className,
  imageWrapperProps,
}: SlideProps) => {
  const handleRemoveClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onRemove(index);
  };

  const handleNameChange = (name: string) =>
    onNameChange && onNameChange(name, index);

  return (
    <Wrapper
      /* used for workspace dnd uploads */
      className={`FileSlide ${className ?? ''}`}
    >
      <ThumbnailContainer aria-hidden={editable}>
        <ItemThumbnail
          itemId=""
          imageUrl={file.preview}
          name={displayName ?? file.name}
          altText={getItemToolTipTitle(displayName, file.name)}
          variant={variant}
          index={index}
          selected={selected}
          expandable={false}
          chipLabels={labelText ? [labelText] : undefined}
          wrapperProps={{
            ...imageWrapperProps,
            onClick: onSelect,
            tabIndex: onSelect ? 0 : undefined,
          }}
        />
      </ThumbnailContainer>

      {editable && (
        <EditNameMask
          name={file.name}
          displayName={displayName}
          onNameChange={handleNameChange}
        />
      )}
      {(warningMessage ||
        file.exists ||
        (!!file.uploadStatus &&
          file.uploadStatus !== UploadStatus.CANCELLED)) && (
        <StatusWrapper $variant={variant}>
          {file.uploadStatus === UploadStatus.UPLOADING ? (
            <CircularProgress size={80} />
          ) : file.uploadStatus === UploadStatus.ERROR ? (
            <ErrorIcon fontSize="inherit" color="error" />
          ) : file.uploadStatus === UploadStatus.IGNORED ? (
            <Tooltip title={getItemToolTipTitle(displayName, file)}>
              <WarningIcon fontSize="inherit" color="error" />
            </Tooltip>
          ) : file.uploadStatus === UploadStatus.UPLOADED ? (
            <SuccessCheckIcon fontSize="inherit" />
          ) : warningMessage ? (
            <Tooltip title={warningMessage}>
              <WarningIcon fontSize="inherit" color="error" />
            </Tooltip>
          ) : file.exists ? (
            <WarningIcon fontSize="inherit" color="error" />
          ) : null}
        </StatusWrapper>
      )}

      {!disableRemoving &&
        (file.uploadStatus === undefined ||
          (removableOnError &&
            (file.uploadStatus === UploadStatus.ERROR ||
              file.uploadStatus === UploadStatus.CANCELLED)) ||
          (removableOnSuccess &&
            file.uploadStatus === UploadStatus.UPLOADED)) && (
          <RemoveButton onClick={handleRemoveClick} aria-label={t`Remove`}>
            <RemoveIcon />
          </RemoveButton>
        )}
    </Wrapper>
  );
};

interface MaskProps {
  name: string;
  displayName?: string;
  onNameChange?: (name: string) => any;
}

export const EditNameMask = ({
  name,
  displayName = name,
  onNameChange,
  ...props
}: MaskProps) => {
  const setOpenModal = useActions(app.actions.setOpenModal);

  const handleEditClick = () => {
    const periodIndex = name.lastIndexOf('.');
    setOpenModal('FILE/EDIT_NAME', {
      initialName: name.substring(0, periodIndex > 0 ? periodIndex : Infinity),
      extendedName: displayName,
      onNameEdit: (newName: string) =>
        onNameChange &&
        onNameChange(
          newName + name.substring(periodIndex > 0 ? periodIndex : Infinity)
        ),
    });
  };
  const handleSpaceDown = (event: KeyboardEvent) => {
    if (event.key === ' ' || event.key === 'Enter') handleEditClick();
  };
  return (
    <Mask {...props}>
      <CaptionMask
        onClick={handleEditClick}
        role="button"
        tabIndex={0}
        onKeyDown={handleSpaceDown}
        aria-label={`${t`Edit name`} ${displayName}`}
      >
        <Typography variant="caption" noWrap>
          {displayName}
        </Typography>
        <StyledEditIcon titleAccess={t`Edit name`} />
      </CaptionMask>
    </Mask>
  );
};

// Used to contain the overflow of thumbnail's hidden text element
const ThumbnailContainer = styled.div`
  overflow: hidden;
  position: relative;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  border-radius: 100%;
  padding: 6px;
  background: ${({ theme }) => theme.palette.primary.main};
  transform: translate(50%, -50%);
  z-index: 100;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.dark};
  }
`;

const Mask = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-end;
  background: transparent;
  top: 0;
  left: -2px;
  right: 0;
  bottom: 0px;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: ${p => p.theme.spacing(1, 1, 0, 0)};

  ${Mask} {
    top: ${p => p.theme.spacing(1)};
    right: ${p => p.theme.spacing(1)};
  }
  ${appearForwards}
`;

const CaptionMask = styled.div<any>`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 12px);
    margin: 6px;
    padding: 2px 4px;
    background: ${({ theme }) => theme.palette.background.thumbnail};
    border-radius: 2px;
    cursor: pointer;
    transition: box-shadow 0.2s;

    &:hover {
      box-shadow: ${({ theme }) => theme.shadows[4]};
    }
  }
`;

const StyledEditIcon = styled(EditIcon)`
  && {
    font-size: 1em;
    display: block;

    @media (any-hover: none) {
      display: block;
    }
  }
`;

const RemoveIcon = styled(CloseIcon)`
  && {
    display: block;
    color: white;
    font-size: 1rem;
  }
`;

const StatusWrapper = styled.div<{ $variant: SlideProps['variant'] }>`
  position: absolute;
  font-size: 80px;
  top: 10px;
  left: 8px;
  right: 10px;
  height: 120px;
  background: #fffa;
  display: flex;
  align-items: center;
  justify-content: center;

  ${p =>
    p.$variant === 'small' &&
    `
    font-size: 20px;
    height: auto;
    bottom: 3px;
    pointer-events: none;
  `}
`;

export default FileSlide;

import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { getMetaFieldWithStaticFields, getUploadLayoutConfig } from './utils';

import config from '~common/config';
import { File, FileContainer } from '~common/content.types';
import { useCustomerMetaFields } from '~common/content.utils';
import ThumbnailSelector from '~common/items/ThumbnailSelector';
import { useLazyGetExistingFileQuery } from '~common/content.api';
import { executeBatchedOperations } from '~common/utils/io.utils';

/**
 * Hook for thumbnail selector logic
 *
 * @param thumbnails Array of thumbnails, from `file.thumbnails`
 *
 * @example
 *
 * const { imageUrl, renderThumbnailSelector } = useThumbnailSelector(file?.thumbnails);
 *
 * return (
 *   <div>
 *     <img src={imageUrl} />
 *     {renderThumbnailSelector()}
 *   </div>
 * )
 */
export function useThumbnailSelector(
  file?: File,
  type: 'thumbnail' | 'preview' = 'thumbnail'
) {
  const {
    thumbnails,
    node: { id },
  } = file ?? { node: {} };

  const [hoveredIndex, setHoveredIndex] = useState<number>();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const currentIndex = hoveredIndex ?? selectedIndex;

  const enabled = useSelector(state =>
    Boolean(state.app.customer?.configById?.['content.browse.multipreview'])
  );

  useEffect(() => {
    if (!thumbnails) {
      return;
    }

    setSelectedIndex(index => Math.min(index, thumbnails?.length ?? 0));
  }, [thumbnails?.length]);

  // Reset index when file has changed
  useEffect(() => {
    setSelectedIndex(0);
  }, [file?.node.id]);

  const uuidSelector = `${type}Uuid` as const;
  const imageUrls = thumbnails?.map(
    thumbnail =>
      `${config.url}/api/v1/files/${id}/contents/thumbnailAttachment.${
        thumbnail[uuidSelector]
      }${
        config.shareKey
          ? '?shareKey=' +
            config.shareKey[0] +
            '/' +
            config.shareKey[1] +
            '/' +
            config.shareKey[2]
          : ''
      }`
  );

  return {
    /**
     * URL of the current thumbnail image
     */
    imageUrl: imageUrls?.[currentIndex],
    /**
     * Render the `ThumbnailSelector` -component if `thumbnails` is not undefined, and has a length of more than 2
     */
    renderThumbnailSelector(
      props?: Partial<ComponentProps<typeof ThumbnailSelector>>
    ) {
      if (!file?.isMasterProduct || !enabled) {
        return null;
      }

      return (
        <ThumbnailSelector
          {...props}
          imageUrls={imageUrls ?? []}
          onChange={index => setSelectedIndex(index)}
          onHover={index => setHoveredIndex(index)}
          selected={currentIndex}
        />
      );
    },
    /**
     * Set the current thumbnail by index. If a number greater than the amount of thumbnails, or less than 0, is supplied
     * or returned, the value is over/underflowed.
     *
     * @example
     *
     * // scrolls the thumbnails backwards
     * <Button onClick={() => setImage(cur => cur - 1)}>Prev</Button>;
     */
    setImage: useCallback(
      (index: number | ((currentIndex: number) => number)) => {
        setSelectedIndex(currentIndex => {
          if (!imageUrls) {
            return 0;
          }

          const nextIndex =
            typeof index === 'function' ? index(currentIndex) : index;

          const clamped = nextIndex % imageUrls?.length;

          return clamped < 0 ? imageUrls.length + clamped : clamped;
        });
      },
      [setSelectedIndex, imageUrls?.length]
    ),
  };
}

/** Checks if the folder is one of the system folders that shouldn't be updated whatsoever */
export function useIsSystemFolder(id?: string) {
  const settings = useSelector(state => state.app.settings);

  return [
    settings?.contentFolderId,
    settings?.workspacesFolderId,
    settings?.archiveFolderId,
    settings?.userContentFolderId,
  ].includes(id);
}

type UseMetaFieldWithStaticFieldsParams = {
  includeDescription: boolean;
  includeInstruction: boolean;
  includeValidityPeriod?: boolean;
  includeArchive?: boolean;
  folder?: File;
};

/** Returns the customer's and static metadata fields  */
export const useMetaFieldWithStaticFields = ({
  includeDescription,
  includeInstruction,
  includeValidityPeriod,
  includeArchive,
  folder,
}: UseMetaFieldWithStaticFieldsParams) => {
  const origMetaFields = useCustomerMetaFields();
  const layoutConfig = getUploadLayoutConfig(folder);
  const customer = useSelector(state => state.app.customer);
  const metaFields = useMemo(
    () =>
      origMetaFields.length > 0
        ? getMetaFieldWithStaticFields(
            layoutConfig,
            origMetaFields,
            includeDescription,
            includeInstruction,
            includeValidityPeriod,
            includeArchive && !!customer?.configById['archive.folder.default']
          )
        : [],
    [folder, origMetaFields]
  );

  return metaFields;
};

/**
 * Hook for checking if there are conflicting filenames in the destination folder.
 *
 * @param itemIds The ids of the files to be moved
 * @param destFolderPath The path of the destination folder
 */
export const useCheckConflictingFilenames = (
  itemIds: string[],
  destFolderPath?: string
) => {
  const filesById = useSelector(state => state.commonContent.filesById);
  const [loading, setLoading] = useState<boolean>(true);
  const [conflictingFiles, setConflictingFiles] = useState<
    { destId: string; filename: string; sourceId: string }[]
  >([]);
  const [getExistingFile] = useLazyGetExistingFileQuery();

  // Assumes that the files are already loaded
  const filesToMove = useMemo(() => {
    return itemIds.map(id => filesById[id]).filter(Boolean) as FileContainer[];
  }, [itemIds, filesById]);

  /** Gets the file name from the path. */
  const getFilename = (file: FileContainer) => {
    const path = file.file?.concrete?.path ?? file.file?.node?.path;
    if (path) {
      return path.split('/').pop();
    }
    return file.file?.name;
  };

  const getId = (file: FileContainer) => {
    return file.file?.concrete?.id ?? file.file?.node?.id ?? file.file?.id;
  };

  useEffect(() => {
    if (destFolderPath && filesToMove.length > 0) {
      // Execute read operations in batches
      executeBatchedOperations(
        {
          operations: filesToMove.map(f => {
            return () =>
              getExistingFile({
                parentPath: destFolderPath,
                filename: getFilename(f) ?? '',
              }).unwrap();
          }),
          windowSize: 10,
        },
        {
          onAllReady: existingFiles => {
            // Map conflicting files to the destination file id and the file name
            const files = existingFiles
              .map((f, index) =>
                !f || 'error' in f || !f.id
                  ? undefined
                  : {
                      destId: f.id,
                      filename:
                        index < filesToMove.length
                          ? getFilename(filesToMove[index]) ?? ''
                          : '',
                      sourceId:
                        index < filesToMove.length
                          ? getId(filesToMove[index]) ?? ''
                          : '',
                    }
              )
              .filter(Boolean) as {
              destId: string;
              filename: string;
              sourceId: string;
            }[];
            // Set conflicting files
            setConflictingFiles(files);
            setLoading(false);
          },
        }
      );
    }
  }, [filesToMove, filesById]);

  return {
    loading,
    conflictingFiles,
  };
};

import { TranslatedData } from './common.types';
import { Group, HomeSettings } from './user.types';
import { Lang } from './utils/i18n';
import { NotificationGroupBase, WorkflowSettingsBase } from './workflows/types';
import { CustomerSpecificActionRight } from './content.types';

export interface CustomerTheme {
  primaryColor: string;
  secondaryColor: string;
  logo: string;
  appBar?: CustomerAppBarTheme;
  uploadForm?: CustomerUploadFormTheme;
}

export interface CustomerAppBarTheme {
  border?: boolean;
  backgroundColor?: string;
  textColor?: string;
}

interface CustomerUploadFormTheme {
  bannerColor?: string;
  bannerLogo?: string;
  bannerCss?: object;
}

type BidirectionalSort<T extends string> = `${T}` | `-${T}`;
export interface CustomerConfig {
  /** Override the default sort of folder contents */
  'folder.browse.settings.default.sort'?:
    | BidirectionalSort<'name'>
    | BidirectionalSort<'created'>
    | BidirectionalSort<'modified'>
    | BidirectionalSort<'description'>;
  /** Override the default sort of search */
  'search.browse.settings.default.sort'?:
    | BidirectionalSort<'orderByName'>
    | BidirectionalSort<'orderByCreated'>
    | BidirectionalSort<'orderByLastUsed'>
    | 'default';
  /** Which material type checkboxes should be hidden from search form.
   * Comma separated list of values picture,video,audio,adobe,cad,text,presentation,folder,masterProduct.
   * TODO: type this if you dare. */
  'search.materialtypes.hide'?: string;
  /** Hide the material types selection altogether */
  'search.materialtypes.disable'?: boolean;
  /** Keep the metafield selectors in view all the time */
  'search.metafilters.show'?: boolean;
  /** Order of the different ways to change an image on a personalized product
   * Comma separated list of values select,search,add.
   * TODO: type this too if you dare. */
  'personalize.image.select.order'?: string;
  'help.links'?: TranslatedData<string>;
  'help.admin.links'?: TranslatedData<string>;
  /** Position of the folder tree in search form */
  'search.tree.folder.position'?: TreePosition;
  /** Position of the category tree in search form */
  'search.tree.position'?: TreePosition;
  /** Wether massupload is allowed or not */
  'material.upload.multiple'?: boolean;
  /** Wether the search or folder tree should be the default tab opened in left panel  */
  'content.browse.settings.mode.default'?: 'search' | 'directory';
  /** Wether the grid or list view should be the default when browsing material  */
  'content.browse.settings.viewmode.default'?: 'grid' | 'list';
  /** Should workspace share mails be sent with the user's email as sender? */
  'send.cart.personal.address'?: boolean;
  /** The metafield that defines the magento order types */
  'metafield.magento.product.type'?: `${number}`;
  /** Should the application ask permission and send tracking data to GA */
  'google.analytics.enable': boolean;
  /** The id of the metafield that should be displayed directly on list view when browsing and searching material */
  'content.browse.settings.metacolumn'?: `custom:meta-field-${number}`;
  /** Should IPTC / EXIF extraction be done in backend */
  'meta.iptc.usebackend'?: boolean;
  /** The metafield id that should be used as the CDN-path value */
  'metafield.material.cdn.path'?: `${number}`;
  /** Domain used in CDN links */
  'cdn.domain'?: string;
  /** The metafield ids that should stay on top and always visible in the search */
  'search.metafields.stayOnTop'?: string;
  /** Set groups open or closed by default */
  'search.metafilters.groups.open'?: string;
  /** Show or hide the group separators  */
  'search.metafilters.separators.show'?: string;
  /* JCD folder ids */
  'jcd.supplierFolderId'?: number;
  'jcd.supplierPoolFolderId'?: number;
  'jcd.supplierPoolZipFolderId'?: number;
  'jcd.cancelledFolderId'?: number;
  'jcd.completedFolderId'?: number;
  'jcd.completedColoroId'?: number;
  'jcd.completedOtherId'?: number;
  'jcd.inProcessingFolderId'?: number;
  'jcd.inProcessingColoroFolderId'?: number;
  'jcd.inProcessingFromColoroFolderId'?: number;
  'jcd.inProcessingProofFolderId'?: number;
  /** Archive default folder */
  'archive.folder.default'?: string;
  'material.link.valid.days'?: string;
  /** User's can mark materials as favorites */
  'material.like.enabled'?: boolean;
  /** Should the user be allowed to navigate outside of the shared folder */
  'content.public.lockin.disabled'?: boolean;
  /** Only admin user can see file technical fields */
  'content.detail.hideAdminFields'?: boolean;
  [key: string]: unknown;
}

export enum UploadMode {
  /** When uploading a file with conflicting name, replaces the original
   * file on server */
  UPDATE,
  /** When uploading a file with conflicting name, creates a new version
   * of the file */
  VERSION,
  /** When uploading a file with conflicting name, renames the file */
  RENAME,
}

export interface CustomerSpecificActionField {
  id: string;
  namesByLang: Record<string, string>;
  type: string;
  optionValues?: Record<string, string>;
}

export interface CustomerSpecificAction {
  id: string;
  namesByLang: Record<string, string>;
  url: string;
  isSendActionType: boolean;
  options: boolean;
  optionsFields?: CustomerSpecificActionField[];
  right: keyof CustomerSpecificActionRight;
}

export interface Customer {
  id: string;
  staticName: string;
  namesByLang: Record<string, string>;
  languages: Lang[];
  defaultLanguage: string;
  authType: number;
  hasLoginPage: boolean;
  configById: CustomerConfig;
  home: HomeSettings;
  passwordValidationRegex: string;
  minimumPasswordLength: number;
  theme: CustomerTheme;
  acceptTermsOfUse: { login: boolean; register: boolean };
  termsOfUse: Record<string, string>;
  downloadConfig?: {
    fileNameModels?: Record<string, { configId: number; label: string }[]>;
    cropFileNameModelId?: number;
  };
  gaTrackingId?: string;
  sendCartAddressCollection: { id: number; email: string }[];
  uploadMode: UploadMode;
  publicCartLogoLink?: string;
  workflows: WorkflowSettingsBase[];
  imageConvertProfiles: Record<number, string>;
  videoConvertProfiles: Record<number, string>;
  audioConvertProfiles: Record<number, string>;
  notificationGroups: NotificationGroupBase[];
  customerConvertProductDefault?: number;
  basketDenyCrawlerBotDefault: boolean;
  infoTexts?: {
    descriptionInfoByLang?: TranslatedData;
    instructionInfoByLang?: TranslatedData;
  };
  customerSpecificActions?: CustomerSpecificAction[];
  fileAttachmentTypesById?: Record<number, TranslatedData>;
}

type ChangeHistoryComment = {
  id: number;
  userId: number;
  username: string;
  text: string | null;
  created: string;
  primaryKey: number;
  readOnlyCommentTO: {
    id: number;
    text: string | null;
    created: string;
  };
};

export type UserBase = {
  id: string;
  /** The only ID a user has when the "isWaiting" boolean is true */
  waitingId?: string;
  email: string;
  username: string;
  firstName: string;
  familyName: string;
  isActive: boolean;
  isLocked: boolean;
  forceChangePassword: boolean;
};

export interface UserResponse extends UserBase {
  language: string;
  sessionLanguage: string;
  homeFolderId: string;
  hasUnreadNews: boolean;
  accountType: 0 | number; // TODO: maybe more specific?
  lastAuthType: 0 | number;
  manyUsersAccountType: boolean;
  company: string;
  mobilePhoneNumber: string;
  phoneNumber: string;
  streetAddress: string;
  streetAddressPostalCode: string;
  streetAddressCity: string;
  deliveryAddress: string;
  deliveryAddressPostalCode: string;
  deliveryAddressCity: string;
  description: string;
  country: string;
  state: string;
  changeHistoryCommentCollection: ChangeHistoryComment[];
  isApprover?: boolean;
  groups: Group[];
}

export interface User extends UserResponse {
  fullName: string;
}

export type UserRight =
  | 'SETTINGS_MODIFY'
  | 'SETTINGS_FRONTPAGE_CUSTOMIZE'
  | 'OWN_SETTINGS_MODIFY'
  | 'USERS_MANAGE'
  | 'OWN_SETTINGS_MANAGE'
  | 'SETTINGS_SHOW'
  | 'USERS_REGISTRATION'
  | 'MATERIAL_MULTIPLE_FILE_UPLOAD'
  | 'ORDERS_SHOW_ALL'
  | 'CARTS_MANAGE'
  | 'SETTINGS_MANAGE'
  | 'MATERIAL_FOLDER_MODIFY'
  | 'NEWS_REMOVE'
  | 'VIDEO_SUBTITLE'
  | 'USERS_ADD'
  | 'NEWS_MODIFY'
  | 'COMMENT_REQUESTS_MAKE'
  | 'COMMENT_REQUESTS_SHOW_ALL'
  | 'NEWS_PUBLISH'
  | 'OWN_SETTINGS_CHANGE_PASSWORD'
  | 'NEWS_MANAGE'
  | 'SHOW_FRONTPAGE'
  | 'SHOW_SEARCH'
  | 'COMMENT_REQUESTS_MANAGE'
  | 'ORDERS_MANAGE'
  | 'PRODUCT_TEMPLATE_MANAGE'
  | 'MATERIAL_MANAGE'
  | 'SHOW_OWN_MATERIAL'
  | 'USERS_MODIFY'
  | 'COMMENT_REQUESTS_SHOW_OWN'
  | 'ORDERS_SHOW_OWN'
  | 'ORDERS_MAKE'
  | 'MAGENTO_ORDER'
  | 'MATERIAL_FOLDER_CREATE'
  | 'USERS_REMOVE'
  | 'MATERIAL_MANAGE_SYNKKA'
  | 'REPORTS_VIEW'
  | 'SHOW_HELP'
  | 'SHOW_ADMIN_HELP'
  | 'MATERIAL_VERSION_MANAGE'
  | 'MATERIAL_FILE_ATTACHMENTS_MANAGE'
  | 'DENY_CRAWLERBOT'
  | 'SET_SHOW_ON_FRONTPAGE'
  | 'SET_CAMPAIGN'
  | 'CONTENT_FORMS_MANAGE'
  | 'CONTENT_FORMS_ADD'
  | 'CONTENT_FORMS_MODIFY'
  | 'CONTENT_FORMS_REMOVE'
  | 'CONTENT_FORMS_CLOSE_INVITATIONS'
  | 'CONTENT_FORMS_SEND'
  | 'META_TREE_MODIFY'
  | 'ARCHIVE_MANAGE'
  | 'DYNAMIC_MATERIAL_MASTER_MANAGE'
  | 'USER_GROUPS_MANAGE'
  | 'MATERIAL_RIGHTS_MANAGE'
  | 'CONSENT_MANAGEMENT';

export type UserRights = Record<UserRight, boolean>;

export interface UserGroup {
  id: string;
  name: string;
  members: User[];
}

export interface AppSettings {
  id: string;
  termsOfUseAccepted: boolean;
  home: HomeSettings;
  theme: CustomerTheme;
  contentFolderId: string;
  userContentFolderId: string;
  workspacesFolderId: string;
  archiveFolderId: string;
  isAdmin: boolean;
  login: { layout: number; backgroundImageUrl: string };
  language: Lang;
  signOnLink?: string;
  signOnAdminLink?: string;
}

export interface DrawerState {
  open: boolean;
  visible: boolean;
  content: string;
  activeTab: string | null;
}

/**
 * Custom positions for the category and folder trees on search form
 *
 * HIDDEN = -1 = Tree is hidden
 * FIRST = 0 = Tree comes first
 * AFTER = 1 = Tree comes after material type selection checkboxes
 * MORE = 2 = Tree is shown behind the show more -button
 */
export enum TreePosition {
  HIDDEN = -1,
  FIRST = 0,
  AFTER = 1,
  MORE = 2,
}

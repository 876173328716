import styled from 'styled-components';

export const InfoTag = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 2px;
  padding: 2px 3px 0;
  white-space: nowrap;
  display: inline-block;

  color: white;
  font-weight: ${p => p.theme.typography.fontWeightMedium};
  font-size: 0.9em;
`;

import React, { useState } from 'react';
import { VisuallyHidden } from 'react-aria';
import { useTableState } from 'react-stately';
import { t } from '@lingui/macro';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

import {
  TableWrapper,
  Table,
  TableHeader,
  Column,
  TableBody,
  Row,
  Cell,
  TableItem,
} from '.';

import { FunctionComponent } from '~common/utils/types.utils';
import { Typography } from '~common/misc/Typography';
import { Fileish } from '~common/content.types';
import { ContentModeType, Criteria } from '~common/common.types';

// Extracted hackily as type not exported :<
type SortDescriptor = NonNullable<
  Parameters<typeof useTableState>[0]['sortDescriptor']
>;

export type ActionsButtonProps = {
  folder?: Fileish;
  mode: ContentModeType;
  redirectTo: (url: string, redirectMode?: 'push' | 'replace') => void;
  openWorkspace: (
    workspaceId: string,
    location: 'sidebar' | 'center',
    options?:
      | {
          saveAsCurrentId?: boolean | undefined;
        }
      | undefined
  ) => void;
  contentCriteria: Criteria;
  currentFolderId?: `${number}`;
  removeFromWorkspace: (itemIds: string[]) => void;
  inWorkspace: boolean;
};

export type ImageCellProps = {
  onSelectItem: (
    index: number,
    eventTargetIndex: number,
    keyCode?: number | null
  ) => void;
};

export type ColumnRendererProps<RowData extends TableItem> = {
  item: RowData;
  children: React.ReactNode;
  rowIndex?: number;
  columnCount?: number;
  props?: ActionsButtonProps | ImageCellProps;
};

export type ColumnDescriptor<RowData extends TableItem> = {
  name: string;
  /** Function used to access some data in a row to be displayed in the column */
  dataAccessor?: (item: RowData) => string;
  /** CSS `width` value */
  width?: string;
  /** Should the column header text be hidden? */
  hideLabel?: boolean;
  /** Cell content alignment (defaults to left) */
  align?: 'left' | 'center' | 'right';
  /** Enable sorting via react-aria? */
  sortable?: boolean;
  /** If column is sortable, should return a normalized string representation of the column to be used for `localeCompare` sorting */
  sortAccessor?: (item: RowData) => string;
  /** Custom component that receives the row item as a prop and renders in a cell */
  renderer?:
    | FunctionComponent<ColumnRendererProps<RowData>>
    | FunctionComponent;
  /** Stop propagation to not select underlying row. Use when renderer is a button. */
  stopRendererPropagation?: boolean;
  /** Overrides column header cell */
  headerElement?: JSX.Element;
  props?: ActionsButtonProps | ImageCellProps;
};

/** Enhanced type for ColumnDescriptor with `key` inferred from `name` */
type KeyedColumnDescriptor<RowData extends TableItem> =
  ColumnDescriptor<RowData> & { key: string };

type Props<T extends TableItem, K extends TableItem['id']> = Omit<
  React.ComponentProps<typeof Table<T>>,
  | 'aria-label'
  | 'children'
  | 'selectedKeys'
  | 'onSelectionChange'
  | 'sortDescriptor'
  | 'onSortChange'
> & {
  /** Aria-label provided to the <table> element, should describe its contents */
  label: string;
  columns: ColumnDescriptor<T>[];
  rows: T[];
  outlined?: boolean;
  scroll?: boolean;
  header?: boolean;
  checkboxColumn?: boolean;
  style?: React.CSSProperties;
  selectedKeys?: Set<K> | 'all';
  onSelectionChange?: (selection: Set<K> | 'all') => void;
  /** Should the first column with selection checkboxes be always visible on top? */
  stickySelectionColumn?: boolean;
  loading?: boolean;
  props?: ActionsButtonProps | ImageCellProps;
};

export function DataTable<T extends TableItem, K extends TableItem['id']>({
  label,
  columns,
  rows,
  style,
  outlined = true,
  scroll = true,
  header = true,
  checkboxColumn = true,
  className,
  loading,
  props,
  ...tableProps
}: Props<T, K>) {
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>();

  const columnMap = columns.reduce(
    (acc, curr) => ({ ...acc, [curr.name]: { ...curr, key: curr.name } }),
    {} as Record<string, KeyedColumnDescriptor<T>>
  );
  const keyedColumns = Object.values(columnMap);

  const sortColumn = sortDescriptor?.column
    ? columnMap[sortDescriptor.column]
    : undefined;

  const sortedRows = sortColumn
    ? [...rows].sort((a, b) => {
        const aData =
          sortColumn.sortAccessor?.(a) ?? sortColumn.dataAccessor?.(a);
        const bData =
          sortColumn.sortAccessor?.(b) ?? sortColumn.dataAccessor?.(b);

        if (!aData || !bData) return 0;

        if (sortDescriptor?.direction === 'ascending') {
          return bData.localeCompare(aData);
        } else {
          return aData.localeCompare(bData);
        }
      })
    : rows;

  return (
    <TableWrapper
      $outlined={outlined}
      $scroll={scroll}
      $header={header}
      $checkboxColumn={checkboxColumn}
      style={style}
      className={className}
    >
      <Table
        aria-label={label}
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        {...tableProps}
      >
        <TableHeader columns={keyedColumns}>
          {column => {
            const data = column.headerElement ? (
              column.headerElement
            ) : column.hideLabel ? (
              <VisuallyHidden>{column.name}</VisuallyHidden>
            ) : (
              column.name
            );

            return (
              <Column
                width={column.width as `${number}`}
                align={column.align}
                allowsSorting={column.sortable}
              >
                {data}
              </Column>
            );
          }}
        </TableHeader>
        <TableBody items={sortedRows}>
          {item => (
            <Row>
              {columnKey => {
                const column = columnMap[columnKey];
                const data = column.dataAccessor?.(item);
                const rowIndex = sortedRows.findIndex(
                  row => row.id === item.id
                );
                if (column.renderer)
                  return (
                    <Cell>
                      <div
                        onPointerDown={e => {
                          if (column.stopRendererPropagation) {
                            e.stopPropagation();
                          }
                        }}
                      >
                        <column.renderer
                          item={item}
                          rowIndex={rowIndex}
                          columnCount={columns.length}
                          props={column.props}
                        >
                          {data}
                        </column.renderer>
                      </div>
                    </Cell>
                  );

                return <Cell>{data}</Cell>;
              }}
            </Row>
          )}
        </TableBody>
      </Table>
      {loading && <LoadingSpinner />}
      {!loading && rows.length === 0 && (
        <EmptyMessage>{t`List is empty`}</EmptyMessage>
      )}
    </TableWrapper>
  );
}

const LoadingSpinner = styled(CircularProgress)`
  align-self: center;
  margin: ${p => p.theme.spacing(8)};
`;

const EmptyMessage = styled(Typography)`
  align-self: center;
  color: ${p => p.theme.palette.grey[500]};
  padding: ${p => p.theme.spacing(4)};
`;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query/react';

import { t } from '@lingui/macro';
import { useDrop } from 'react-dnd';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { KeyboardArrowLeft } from '@mui/icons-material';

import FileBrowser from '../common/FileBrowser';
import FileSelector from '../common/FileSelector';
import Thumbnail from '../common/Thumbnail';
import { useGetDefaultWorkspaceId } from './api';
import {
  useOpenNewWorkspace,
  useAddToWorkspace,
  useUploadToWorkspace,
} from './hooks';
import { workspaces } from './model';
import WorkspaceTree from './WorkspaceTree';
import { WorkspaceBottomActions } from './WorkspaceBottomActions';
import { DeleteDropzone } from './DeleteDropzone';
import { UploadDropzone } from './UploadDropzone';

import { app } from '~common/app.model';
import { getLangValue } from '~common/app.utils';
import PageBar from '~sections/PageBar';
import PageBottomBar from '~sections/PageBottomBar';
import ActionBar from '~sections/ActionBar';
import PageContent from '~sections/PageContent';
import ModalButton from '~navigation/ModalButton';
import Typography from '~misc/Typography';
import { defaultCriteria } from '~common/content.constants';

import { AddIcon, BackIcon, FoldersIcon, EditIcon } from '~misc/icons';

import { Checkbox } from '~common/inputs/Checkbox';
import { useRedirectTo } from '~common/navigation/useRedirectTo';
import { useActions } from '~common/utils/hooks.utils';
import { useGetContentsQuery, useGetFolderQuery } from '~common/content.api';
import Button from '~common/inputs/Button';
import { DndItem, File, UploadFile } from '~common/content.types';
import { Results } from '~common/common.types';
import FileSlide from '~common/items/FileSlide';
import { combineRefs } from '~common/utils/fn.utils';
import { getAcceptedDndTypes } from '~common/misc/drag/utils';

interface SelectedFolderProps {
  item?: File;
  params: {
    onSelectFile: (file: File, index: number) => void;
    language: string;
    defaultLanguage: string;
  };
}

const isAppFile = (item?: File | UploadFile): item is File =>
  item !== undefined && 'node' in item;

const SelectedFolderComponent = ({ params, item }: SelectedFolderProps) => {
  const { setCheckedWorkspaceContent } = useActions(workspaces.actions);
  const checkedContent = useSelector(
    state => state.workspaces.checkedWorkspaceContent
  );
  const { data: contents } = useGetContentsQuery(
    item?.isFolder ? { id: item.node.id, criteria: defaultCriteria } : skipToken
  );

  const childrenItems = contents?.items.filter(item => !item.isFolder) ?? [];
  const fileCount =
    (isAppFile(item) && (item.fileCount ?? item.fileCountAll)) || -1;

  return fileCount > 0 ? (
    <FolderContents>
      <FileSelector
        files={childrenItems}
        fileCount={fileCount}
        onSelectFile={params.onSelectFile}
        showFilter={false}
        showCheckboxes={isAppFile(item) && item.node.fileType === 'nt:folder'}
        checkedContent={new Set(checkedContent)}
        setCheckedContent={ids => setCheckedWorkspaceContent([...ids])}
        centered
        language={params.language}
        defaultLanguage={params.defaultLanguage}
        dragSource="sidebar"
        contentDescription={t`Contents of ${getLangValue(item?.namesByLang)}.`}
      />
    </FolderContents>
  ) : null;
};

type ThumbnailProps = Omit<
  React.ComponentProps<typeof Thumbnail>,
  'centered' | 'expandable' | 'showDividers' | 'animate' | 'itemId'
> & {
  id: string;
  item: File | UploadFile;
  index: number;
};

const ThumbnailComponent = ({ item, ...rest }: ThumbnailProps) => {
  const { setWorkspaceItemChecked } = useActions(workspaces.actions);

  const checkedWorkspaceContent = useSelector(
    state => state.workspaces.checkedWorkspaceContent
  );

  const { data: contents } = useGetContentsQuery(
    item.isFolder ? { id: item.node.id, criteria: defaultCriteria } : skipToken
  );

  const childrenItems = item.isFolder
    ? contents?.items.filter(item => !item.isFolder) ?? []
    : undefined;

  let allChildrenChecked = false;
  let someChildrenChecked = false;
  if (childrenItems) {
    allChildrenChecked =
      childrenItems.length > 0 &&
      childrenItems.every(item =>
        checkedWorkspaceContent.includes(item.node.id)
      );
    someChildrenChecked =
      childrenItems.length > 0 &&
      childrenItems.some(item =>
        checkedWorkspaceContent.includes(item.node.id)
      );
  }

  const checked = item.node && checkedWorkspaceContent.includes(item.node.id);
  // Whenever all children become checked, we want to check the folder too
  useEffect(() => {
    if (item.isFolder && checked !== allChildrenChecked) {
      setWorkspaceItemChecked({ item, checked: allChildrenChecked });
    }
  }, [allChildrenChecked]);

  // Whenever the folder becomes checked, we want to check all child items too
  useEffect(() => {
    if (item.isFolder && checked !== allChildrenChecked) {
      setWorkspaceItemChecked({ item, checked: !!checked, childrenItems });
    }
  }, [checked]);

  return isAppFile(item) ? (
    <Thumbnail
      {...rest}
      item={item}
      expandable={item.isFolder}
      showDividers={false}
      itemId={item.node.id}
      fullwidth={false}
      showChevron={item.isFolder}
      inWorkspace
      showCheckbox
      indeterminate={!checked && someChildrenChecked}
      dragSource="sidebar"
      childrenItems={childrenItems}
      key={item.previewStatus}
    />
  ) : (
    <FileSlide
      {...rest}
      file={item}
      editable={false}
      disableRemoving={true}
      onRemove={() => {}}
    />
  );
};

export default function Workspace() {
  const language = useSelector(state => state.app.settings?.language);
  const defaultLanguage = useSelector(
    state => state.app.customer?.defaultLanguage
  );
  const checkedWorkspaceContent = useSelector(
    state => state.workspaces.checkedWorkspaceContent
  );
  const workspaceId = useSelector(state => state.workspaces.currentWorkspaceId);
  const { data: defaultWorkspaceData } = useGetDefaultWorkspaceId();

  const { setOpenModal } = useActions(app.actions);
  const { setCheckedWorkspaceContent } = useActions(workspaces.actions);

  const redirectTo = useRedirectTo();
  const { openNewWorkspace } = useOpenNewWorkspace();
  const { addToWorkspace } = useAddToWorkspace();

  const modalOpen = useSelector(state => state.app.openModals.length > 0);

  const [criteria, setCriteria] = useState(defaultCriteria);
  const [showTree, setShowTree] = useState(false);
  // If some preview images are generating, we should refetch the workspace
  // contents periodically until we get the preview images
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const { files: uploadFiles = [], ...uploadToWorkspace } =
    useUploadToWorkspace(workspaceId);

  const fileListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Scroll to see upload progress of dropped files
    if (uploadFiles.length > 0 && fileListRef.current) {
      const firstUploadFile = fileListRef.current.querySelector('.FileSlide');
      firstUploadFile?.scrollIntoView();
    }
  }, [uploadFiles.length]);

  // We don't want the contentsQuery to fire unless relevant parts of
  // `criteria` change, so we strip `selectedId` and `selectedIndex`
  const { selectedId, selectedIndex, ...queryCriteria } = criteria;
  const memoedCriteria = useMemo(() => queryCriteria, [criteria]);

  const {
    data: contents,
    isFetching: contentsIsFetching,
    currentData: contentCurrentData,
  } = useGetContentsQuery(
    workspaceId
      ? {
          id: workspaceId,
          criteria: memoedCriteria,
        }
      : skipToken,
    { pollingInterval: shouldRefetch ? 5000 : undefined }
  );

  useEffect(() => {
    if (contents?.items.some(file => file.previewStatus === 'GENERATING')) {
      setShouldRefetch(true);
    } else {
      setShouldRefetch(false);
    }
  }, [contents]);

  const selectedItem =
    contents && contents.items && criteria.selectedIndex !== -1
      ? contents.items[criteria.selectedIndex]
      : undefined;

  // Remove uploadFiles after the workspace content has refreshed
  useEffect(() => uploadToWorkspace.resetFiles?.(), [contents?.items?.length]);

  // NOTE: Filters out folders as per old UI. If removed, add support for nested folders in workspace
  const allSelectedFolderContents = contents;
  const filteredItems =
    allSelectedFolderContents &&
    allSelectedFolderContents.items &&
    allSelectedFolderContents.items.filter(item => !item.isFolder);

  const selectedFolderContents = {
    ...allSelectedFolderContents,
    items: filteredItems,
  };

  // Reset selected item if children has been removed
  useEffect(() => {
    if (!selectedFolderContents?.items?.length) {
      setCriteria(criteria => ({ ...criteria, selectedIndex: -1 }));
    }
  }, [allSelectedFolderContents]);

  const results: Results<File | UploadFile> = {
    totalCount:
      (contents?.items ? Number(contents.totalCount) : -1) + uploadFiles.length,
    items: [...(contents?.items ?? []), ...uploadFiles],
    status: !contents || !contents.items ? 'loading' : null,
  };

  const { data: workspace, isFetching: workspaceIsFetching } =
    useGetFolderQuery(
      workspaceId ? { id: workspaceId, include: 'emails' } : skipToken
    );

  // We don't want to display loading state when refetching with same query params
  const workspaceLoading =
    workspaceIsFetching || (contentsIsFetching && !contentCurrentData);

  const workspaceName =
    workspace &&
    !workspace.removed &&
    (getLangValue(workspace.namesByLang) ?? workspace.name);

  // Clear selected item when changing workspace
  useEffect(() => {
    setCriteria({
      ...criteria,
      selectedIndex: -1,
    });
  }, [workspaceId]);

  const onSelectFile = (item, index) => {
    setOpenModal('FILE/VIEW', {
      item,
      indexInfo: {
        selectedIndex: index % criteria.pageSize,
        pageSize: criteria.pageSize,
        page: Math.floor(index / criteria.pageSize),
      },
    });
  };

  const allChecked = useMemo(() => {
    return (
      contents?.items?.every(item =>
        checkedWorkspaceContent.includes(item.node.id)
      ) ?? false
    );
  }, [checkedWorkspaceContent, contents]);

  /** Selects each item in current workspace */
  const checkAll = (target: boolean) => {
    if (target) {
      if (!contents?.items) return;
      const itemSet = new Set<string>();
      contents.items.forEach(item => itemSet.add(item.node.id));
      setCheckedWorkspaceContent([...itemSet]);
    } else {
      setCheckedWorkspaceContent([]);
    }
  };

  const onSelectItem = (index, eventTargetIndex) => {
    const itemIndex = index !== -1 ? index : eventTargetIndex;
    const item = results.items[itemIndex];
    if (isAppFile(item)) {
      if (item.isFolder) {
        const fileCount = item.fileCount ?? item.fileCountAll;
        if (fileCount === 0) {
          return;
        }

        setCriteria({
          ...criteria,
          selectedIndex: index,
        });
      } else {
        onSelectFile(item, itemIndex);
      }
    }
  };

  // DND
  const onDrop = droppedItem => {
    if (workspaceId) {
      addToWorkspace({ workspaceId, itemIds: droppedItem.itemIds });
    }
  };

  const [{ isOver, isOverCurrent, canDrop }, drop] = useDrop(
    {
      accept:
        workspace && !workspace.removed ? getAcceptedDndTypes(workspace) : [],
      drop: (item: DndItem, monitor) => {
        const didDrop = monitor.didDrop();
        if (didDrop) {
          return;
        }
        onDrop(item);
      },
      canDrop: (props, monitor) =>
        monitor.isOver({ shallow: true }) &&
        monitor.getItem().dragSource !== 'sidebar',
      collect: monitor => ({
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
      }),
    },
    [workspace, addToWorkspace]
  );

  const itemComponentParams = {
    folder: workspace,
    selectedFolderContents,
    onSelectFile,
    criteria,
    language: language ?? defaultLanguage ?? '',
    defaultLanguage: defaultLanguage ?? '',
  };

  const isDropHover = isOverCurrent && canDrop;

  if (showTree)
    return (
      <>
        <PageBar padding={2} paddingHoriz={1}>
          <TopActionBar left>
            <IconButton
              color="primary"
              size="small"
              aria-label={t`Go back`}
              onClick={e => {
                e.stopPropagation();
                setShowTree(false);
              }}
            >
              <BackIcon color="primary" />
            </IconButton>
            <Title variant="h2">{t`Choose workspace`}</Title>
          </TopActionBar>
        </PageBar>
        <TreePageContent padding={1} isHover={isDropHover}>
          <WorkspaceTree
            isSaved={workspaceId !== defaultWorkspaceData?.id}
            onSelect={() => setShowTree(false)}
          />
        </TreePageContent>
      </>
    );

  // Paging disabled
  const onUpdatePaging = () => {};

  if (!workspace || workspace.removed) return null;

  // REFACTOR: TabContent, Page, PageContent
  return (
    <>
      <TopBar padding={2} paddingHoriz={1}>
        <Tooltip title={t`Open workspace in center`}>
          <Button
            onClick={() => redirectTo(`/workspaces/${workspaceId}`)}
            aria-label={
              workspaceId !== defaultWorkspaceData?.id
                ? `${t`Workspace`}: ${workspaceName}`
                : undefined
            }
            startIcon={<OpenInCenterIcon />}
            color="inherit"
          >
            <Title variant="h2">{workspaceName}</Title>
          </Button>
        </Tooltip>

        {workspaceId !== defaultWorkspaceData?.id &&
          workspace.userRights?.edit && (
            <Tooltip title={t`Edit workspace`}>
              <ModalButton
                isIconButton
                modalType="WORKSPACE/EDIT"
                aria-label={t`Edit workspace`}
                modalProps={{ folder: workspace }}
                size="small"
              >
                <EditIcon />
              </ModalButton>
            </Tooltip>
          )}
      </TopBar>

      <PageBar>
        <Checkbox
          isSelected={allChecked}
          onChange={checkAll}
          accessibleLabel={t`Select all`}
        >
          <Typography variant="button">{t`Select all`}</Typography>
        </Checkbox>

        <TopActionBar>
          <Tooltip title={t`Choose workspace`}>
            <IconButton
              color="primary"
              size="small"
              aria-label={t`Choose workspace`}
              onClick={e => {
                e.stopPropagation();
                setShowTree(true);
              }}
            >
              <FoldersIcon color="primary" />
            </IconButton>
          </Tooltip>

          {workspaceId === defaultWorkspaceData?.id &&
          results.totalCount > 0 ? (
            <ModalButton
              color="primary"
              size="small"
              aria-label={t`Create workspace`}
              variant="contained"
              isIconButton
              modalType="WORKSPACE/CONFIRM_OPEN"
              modalProps={{ openInLocation: 'sidebar' }}
              disabled={workspaceLoading}
            >
              <Tooltip title={t`Create workspace`}>
                <AddIcon color="primary" />
              </Tooltip>
            </ModalButton>
          ) : (
            <Tooltip title={t`Create workspace`}>
              <IconButton
                color="primary"
                size="small"
                aria-label={t`Create workspace`}
                disabled={workspaceLoading}
                onClick={e => {
                  e.stopPropagation();
                  openNewWorkspace('sidebar');
                }}
              >
                <AddIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </TopActionBar>
      </PageBar>

      <StyledPageContent
        padding={3}
        paddingHoriz={5}
        isHover={isDropHover}
        highlightDropTargets={isOver}
        ref={combineRefs(drop, fileListRef)}
        id="workspace"
        tabIndex={-1}
      >
        {results.status === 'loading' || results.totalCount > 0 ? (
          <FileBrowser
            id="workspace"
            criteria={criteria}
            results={results}
            collection={workspaceId ?? ''}
            checkedItems={new Set(checkedWorkspaceContent)}
            setCheckedContent={ids => setCheckedWorkspaceContent([...ids])}
            selectedItem={selectedItem}
            onSelectItem={onSelectItem}
            onUpdatePaging={onUpdatePaging}
            itemComponentParams={itemComponentParams}
            ThumbnailComponent={ThumbnailComponent}
            SelectedFileComponent={undefined}
            SelectedFolderComponent={SelectedFolderComponent}
            showItemOnFullScreen={false}
            loading={workspaceLoading}
          />
        ) : (
          <DropLabel variant="h3">
            {t`Drag material here to share or download them`}
          </DropLabel>
        )}
      </StyledPageContent>

      <PageBottomBar paddingHoriz={2}>
        {workspace && !workspace.removed && (
          <WorkspaceBottomActions
            workspace={workspace}
            isShareDisabled={
              checkedWorkspaceContent.length > 0 ||
              results.totalCount < 1 ||
              workspace?.removed ||
              !workspace?.userRights?.share
            }
            xsmall={language !== 'fi'}
          />
        )}
      </PageBottomBar>

      <DeleteDropzone dragSource="sidebar" />
      {uploadToWorkspace.allowed && !modalOpen && (
        <UploadDropzone addUploadFiles={uploadToWorkspace.addFiles} />
      )}
    </>
  );
}

const FolderContents = styled.div`
  background-color: ${props => props.theme.palette.background.default};
  border: 1px solid ${props => (props.theme.palette as any).border.card};
  border-radius: 4px;

  margin: 5px 0 ${props => props.theme.spacing(2)} 0;
`;

const StyledPageContent = styled(PageContent)<{
  isHover?: boolean;
  highlightDropTargets?: boolean;
}>`
  height: 100%;
  min-height: 50vh; /* temporary safary quick fix */
  transition: all 0.4s;

  ${p => p.isHover && `background-color: ${p.theme.palette.grey[300]};`}

  ${p =>
    p.highlightDropTargets &&
    `[data-drop-target="true"] {
      box-shadow: ${p.theme.shadows[4]};
    }
    :not([data-drop-target="true"]) {
      opacity: 0.9
    }
  `}
`;

const TreePageContent = styled(StyledPageContent)`
  flex-grow: 1;
`;

const DropLabel = styled(Typography)`
  position: absolute;
  color: ${({ theme }) => theme.palette.grey[600]};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 120px;
  padding-bottom: 24px;
`;

const TopBar = styled(PageBar)`
  & > button {
    margin-right: unset;
  }
  & svg {
    font-size: 1.2rem;
  }
  justify-content: space-between;
  padding-right: ${p => p.theme.spacing(3)};
`;

const TopActionBar = styled(ActionBar)<{ left?: boolean }>`
  display: flex;
  justify-content: ${({ left }) => (left ? 'flex-start' : 'flex-end')};
  align-items: center;
`;

const Title = styled(Typography)`
  && {
    font-size: 1rem;
  }
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: initial;
`;

const OpenInCenterIcon = styled(KeyboardArrowLeft)`
  && {
    color: ${p => p.theme.palette.grey[500]};
  }
`;
